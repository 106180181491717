import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 小人国全部国家信息
export function xrgCountries() {
  return getRequest("/xrg/area/countries");
}

// 小人国地区列表
export function xrgAreaPageData(params = {}) {
  return postRequest("/xrg/area/getPageData", params);
}
