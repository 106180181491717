import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 邮件列表
export function mailPageData(params = {}) {
  return postRequest("/work/mail/getPageData", params);
}

// 删除邮件
export function delMail(id) {
  return deleteRequest("/work/mail/" + id);
}

// 添加邮件
export function addMail(params = {}) {
  return postRequest("/work/mail", params);
}

// 更新邮件
export function upMail(params = {}) {
  return putRequest("/work/mail", params);
}

// 发送邮件
export function sendMail(id) {
  return postRequest("/work/mail/" + id);
}
