import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";
import {downloadRequest} from "@/utils/download";

// 获取全部角色信息
export function roles() {
  return getRequest("/sys/role/getAll");
}

// 角色列表
export function rolePageData(params = {}) {
  return postRequest("/sys/role/getPageData", params);
}

// 删除角色
export function delRole(id) {
  return deleteRequest("/sys/role/" + id);
}

// 添加角色
export function addRole(params = {}) {
  return postRequest("/sys/role", params);
}

// 更新角色
export function upRole(params = {}) {
  return putRequest("/sys/role", params);
}

// 导出角色
export function roleExport() {
  return downloadRequest("/sys/role/export");
}