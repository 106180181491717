const genshin = {
    state: {
        entries: ['攻击力', '防御力', '生命值', '元素充能效率', '元素精通', '暴击率', '暴击伤害', '物理伤害加成'],
        attributes: ['风', '火', '雷', '水', '冰', '岩', '草'],
        armsTypes: ['弓箭', '单手剑', '双手剑', '枪', '法器'],
        genshinAddresses: ['蒙德', '璃月', '稻妻', '至冬', '须弥', '枫丹', '纳塔'],
    },
    mutations: {

    },
    actions: {}
}

export default genshin