import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 获取所有等级权限、等级权限操作
export function permiss() {
  return getRequest("/sys/permiss");
}

// 权限 - 获取所有菜单
export function permissMenus() {
  return getRequest("/sys/permiss/menus");
}

// 根据等级id查询菜单id
export function permissMenuIdsByLid(id) {
  return getRequest("/sys/permiss/mid/" + id);
}

// 添加等级权限
export function addPermiss(params) {
  return postRequest("/sys/permiss", params);
}

// 删除等级权限
export function delPermiss(id) {
  return deleteRequest("/sys/permiss/" + id);
}

// 更新等级权限
export function upPermiss(params) {
  return putRequest("/sys/permiss", params);
}