import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "@/utils/api.js";

// 获取用户所有的权限开关
export function getAuthStatus(params = {}) {
    return getRequest("/auth/getAuthStatus");
}

// 用户权限开关控制
export function switchStatus(params = {}) {
    return postRequest("/auth/switchStatus", params);
}