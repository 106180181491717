<template>
  <div>

    <div style="margin-top: 15px">
      <div>
        <el-date-picker
            style="width: 200px;margin-right: 20px"
            size="mini"
            v-model="searchAccountPlan.planDate"
            type="month"
            value-format="yyyyMM"
            placeholder="计划月份"
            @change="searchAccountPlan.currentPage = 1; searchAccountPlan.size = 10; initAccountPlan()"
        >
        </el-date-picker>
        <el-select
            v-model="searchAccountPlan.accountBookId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="账本"
            clearable
            filterable
            @change="searchAccountPlan.currentPage = 1; searchAccountPlan.size = 10; initAccountPlan()"
        >
          <el-option
              v-for="item in accountBooks"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchAccountPlan.accountCategoryId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="支出类型"
            clearable
            filterable
            @change="searchAccountPlan.currentPage = 1; searchAccountPlan.size = 10; initAccountPlan()"
        >
          <el-option
              v-for="item in accountTypes"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <div style="margin-top: 10px">
          <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchAccountPlan.planDate = ''; searchAccountPlan.accountBookId = null; searchAccountPlan.accountCategoryId = null">
            重置
          </el-button>
          <el-button
              type="success"
              size="mini"
              icon="el-icon-search"
              @click="searchAccountPlan.currentPage = 1; searchAccountPlan.size = 10; initAccountPlan()"
          >
            搜索
          </el-button>
          <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              @click="showAddAccountPlanView"
          >
            添加月计划配置
          </el-button>
        </div>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="accountPlanData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="计划月份"
            align="center"
            prop="planDate"
        ></el-table-column>
        <el-table-column
            label="账本名称"
            align="center"
            prop="bookName"
        ></el-table-column>
        <el-table-column
            label="支出类型名称"
            align="center"
            prop="typeName"
        ></el-table-column>
        <el-table-column
            label="月计划支出金额"
            align="center"
            prop="expenditureAmt"
        ></el-table-column>
        <el-table-column
            label="当前支出金额"
            align="center"
            prop="expenditureAmtSummary"
        ></el-table-column>
        <el-table-column
            label="创建时间"
            width="200"
            align="center"
            prop="createTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="更新时间"
            width="200"
            align="center"
            prop="updateTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button size="mini" style="padding: 3px" type="primary" @click="showUpdateAccountPlanView(scope.row)">编辑</el-button>
            <el-button size="mini" style="padding: 3px" type="danger" @click="deleteAccountPlan(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
          :current-page="searchAccountPlan.currentPage"
          :page-size="searchAccountPlan.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="400px">
      <el-form ref="accountPlanForm" :model="accountPlan" :rules="rules">
        <table>
          <tr>
            <el-form-item label="计划月份" label-width="130px" prop="planDate">
              <el-date-picker
                  style="width: 200px"
                  v-model="accountPlan.planDate"
                  type="month"
                  value-format="yyyyMM"
                  placeholder="选择计划月份"
              >
              </el-date-picker>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账本" label-width="130px" prop="accountBookId">
              <el-select
                  v-model="accountPlan.accountBookId"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="账本"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in accountBooks"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="支出类型" label-width="130px" prop="accountCategoryId">
              <el-select
                  v-model="accountPlan.accountCategoryId"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  @change="categoryChange"
                  placeholder="支出类型"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in accountTypes"
                    :key="item.id"
                    :label="item.typeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="月计划支出金额" label-width="130px" prop="expenditureAmt">
              <el-input-number
                  size="mini"
                  style="width: 200px"
                  v-model="accountPlan.expenditureAmt"
                  placeholder="请输入月计划支出金额"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="当前支出金额" label-width="130px">
              <el-input-number
                  size="mini"
                  style="width: 200px"
                  v-model="expenditureAmtSummary"
                  disabled
              ></el-input-number>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAccountPlan">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import store from "@/store";
import {accountBooks, accountTypesByCategory, getExpenditureAmtSummary} from "@/api/person/accountRecord";
import {
  accountPlanPageData,
  addAccountPlan,
  delAccountPlan,
  upAccountPlan
} from "@/api/person/accountPlan";

export default {
  name: "AccountPlan",
  data() {
    return {
      accountPlanData: [],
      accountBooks: [],
      accountTypes: [],
      loading: false,
      searchAccountPlan: {
        planDate: '',
        accountBookId: null,
        accountCategoryId: null,
        currentPage: 1,
        size: 10,
      },
      accountPlan: {
        id: null,
        accountBookId: null,
        accountCategoryId: null,
        planDate: '',
        expenditureAmt: '',
      },
      expenditureAmtSummary: 0,
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        accountBookId: [{required: true, message: "请选择账本", trigger: "change"}],
        accountCategoryId: [{required: true, message: "请选择类别", trigger: "change"}],
        planDate: [{required: true, message: "请选择计划月份", trigger: "change"}],
        expenditureAmt: [{required: true, message: "请输入支付月计划金额", trigger: "blur"}],
      },
      roleButton: store.getters.currentRole,
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted() {
    this.initMonth();
    this.initAccountBooks();
    this.initExpenditureTypes();
    this.initAccountPlan();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initMonth() {
      var y = new Date().getFullYear();
      var m = new Date().getMonth();
      const planDate = y + (m + 1).toString().padStart(2, '0');
      this.accountPlan.planDate = planDate;
    },
    initAccountBooks() {
      accountBooks().then(resp => {
        if (resp) {
          this.accountBooks = resp;
        }
      })
    },
    initExpenditureTypes() {
      accountTypesByCategory(1).then(resp => {
        if (resp) {
          this.accountTypes = resp;
        }
      })
    },
    initAccountPlan() {
      this.loading = true;
      accountPlanPageData(this.searchAccountPlan).then(resp => {
        this.loading = false;
        if (resp) {
          this.accountPlanData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchAccountPlan.currentPage = page;
      this.initAccountPlan();
    },
    sizeChange(size) {
      this.searchAccountPlan.size = size;
      this.initAccountPlan();
    },
    categoryChange() {
      this.getExpenditureAmtSummary();
    },
    getExpenditureAmtSummary() {
      this.expenditureAmtSummary = 0;
      let params = {
        accountBookId: this.accountPlan.accountBookId,
        accountCategoryId: this.accountPlan.accountCategoryId,
        planDate: this.accountPlan.planDate
      }
      getExpenditureAmtSummary(params).then(resp => {
        this.expenditureAmtSummary = resp;
      })
    },
    deleteAccountPlan(data) {
      this.$confirm(
          "此操作将永久删除[" + data.planDate + "]的配置, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delAccountPlan(data.id).then(resp => {
              if (resp) {
                this.initAccountPlan();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddAccountPlanView() {
      this.accountPlan = {
        id: null,
        accountBookId: null,
        accountCategoryId: null,
        planDate: '',
        expenditureAmt: ''
      };
      this.initMonth();
      this.expenditureAmtSummary = 0;
      this.dialogVisible = true;
      this.title = "添加月计划配置";
    },
    showUpdateAccountPlanView(data) {
      // 拷贝
      this.accountPlan = Object.assign({}, data);
      this.dialogVisible = true;
      this.getExpenditureAmtSummary();
      this.title = "编辑月计划配置";
    },
    closeDialog() {
      this.$refs["accountPlanForm"].resetFields();
    },
    doAccountPlan() {
      if (this.accountPlan.id) {
        // 编辑
        this.$refs["accountPlanForm"].validate((valid) => {
          if (valid) {
            upAccountPlan(this.accountPlan).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initAccountPlan();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["accountPlanForm"].validate((valid) => {
          if (valid) {
            addAccountPlan(this.accountPlan).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initAccountPlan();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>