import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 幻塔意志列表
export function hottaWillPageData(params = {}) {
  return postRequest("/hotta/will/getPageData", params);
}

// 删除幻塔意志
export function delHottaWill(id) {
  return deleteRequest("/hotta/will/" + id);
}

// 添加幻塔意志
export function addHottaWill(params = {}) {
  return postRequest("/hotta/will", params);
}

// 更新幻塔意志
export function upHottaWill(params = {}) {
  return putRequest("/hotta/will", params);
}
