<template>
  <div>
    <div>
      <div>
        <el-select
            v-model="searchVersion.source"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="渠道"
            clearable
            filterable
            @change="searchVersion.currentPage = 1; searchVersion.size = 10; initVersion()"
        >
          <el-option :value="1" label="Android">Android</el-option>
          <el-option :value="2" label="IOS">IOS</el-option>
        </el-select>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="版本号"
            v-model="searchVersion.version"
            clearable
            @keydown.enter.native="searchVersion.currentPage = 1; searchVersion.size = 10; initVersion()"
        ></el-input>
        <el-select
            v-model="searchVersion.isUpdate"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="是否强制更新"
            clearable
            filterable
            @change="searchVersion.currentPage = 1; searchVersion.size = 10; initVersion()"
        >
          <el-option value="true" label="是">是</el-option>
          <el-option value="false" label="否">否</el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchVersion.source = null; searchVersion.version = ''; searchVersion.isUpdate = null;">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchVersion.currentPage = 1; searchVersion.size = 10; initVersion()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddVersionView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Version') !== -1"
        >
          添加版本
        </el-button>
<!--        <el-upload
            style="margin-top: 15px"
            ref="upload"
            :show-file-list="false"
            :action="filesUploadUrl"
            :on-success="filesUploadSuccess"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Version') !== -1"
        >
          <el-button icon="el-icon-plus" type="info">上传apk</el-button>
        </el-upload>-->
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="versionData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="渠道"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.source === 1">Android</el-tag>
            <el-tag v-else>IOS</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="版本号"
            width="200"
            align="center"
            prop="version"
        ></el-table-column>
        <el-table-column
            label="下载地址"
            width="500"
            align="center"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.url && commonApi.toText(scope.row.url).length > 50" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.url.replace(/\n/gm, '<br>')" slot="content">{{scope.row.url}}</span>
              <span v-if="scope.row.url" v-html="$options.filters.ellipsis(scope.row.url)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.url" v-html="commonApi.toText(scope.row.url)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="是否强制更新"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.isUpdate">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="版本描述" align="center">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.remark" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
              <!--<span>{{scope.row.context | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="60"
        >
          <template slot-scope="scope">
            <!-- <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Version') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateVersionView(scope.row)">编辑</el-button> -->
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Version') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteVersion(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchVersion.currentPage"
          :page-size="searchVersion.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="800px">
      <el-form ref="versionForm" :model="version" :rules="rules">
        <table>
          <tr>
            <el-form-item label="渠道" prop="sources">
              <el-select
                  v-model="version.sources"
                  size="mini"
                  multiple
                  style="width: 200px"
                  placeholder="渠道"
                  clearable
                  filterable
              >
                <el-option value="1" label="Android">Android</el-option>
                <el-option value="2" label="IOS">IOS</el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="版本号" prop="version">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="version.version"
                  placeholder="请输入版本号"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="下载地址" prop="url">
              <el-input
                  size="mini"
                  style="width: 600px"
                  v-model="version.url"
                  placeholder="请输入下载地址"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="是否强制更新">
              <el-switch
                  v-model="version.isUpdate"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  style="padding-top: 6px">
              </el-switch>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="版本描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doVersion">确 定</el-button>
        </span>
    </el-dialog>


    <!-- 查看下载地址 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="urlTitle"
        :visible.sync="urlDialogVisible"
        width="800px">
      <el-form>
        <table>
          <tr>
            <el-form-item label="下载地址">
              <el-input
                  size="mini"
                  disabled
                  style="width: 600px"
                  v-model="url"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="urlDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
// wangeditor富文本
import E from "wangeditor"
import store from "@/store";
import {addVersion, delVersion, upVersion, versionPageData} from "@/api/sys/version";
let editor;

export default {
  name: "Version",
  data() {
    return {
      versionData: [],
      loading: false,
      searchVersion: {
        source: null,
        version: '',
        isUpdate: null,
        currentPage: 1,
        size: 10,
      },
      version: {
        id: null,
        source: null,
        sources: null,
        version: '',
        url: '',
        remark: '',
        isUpdate: false
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        source: [{required: true, message: "请选择渠道", trigger: "change"}],
        sources: [{required: true, message: "请选择渠道", trigger: "change"}],
        version: [{required: true, message: "请输入版本号", trigger: "blur"}],
        url: [{required: true, message: "请选择下载地址", trigger: "change"}],
      },
      filesUploadUrl: window.server.filesUploadUrl + "/files/apkUpload",
      url: '',
      urlTitle: '',
      urlDialogVisible: false,
      roleButton: store.getters.currentRole,
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      let input = value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
      if (input.length > 50) {
        return input.slice(0, 50) + '...';
      } else {
        return input;
      }
    }
  },
  computed: {

  },
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initVersion();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initVersion() {
      this.loading = true;
      versionPageData(this.searchVersion).then(resp => {
        this.loading = false;
        if (resp) {
          this.versionData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchVersion.currentPage = page;
      this.initVersion();
    },
    sizeChange(size) {
      this.searchVersion.size = size;
      this.initVersion();
    },
    deleteVersion(data) {
      this.$confirm(
          "此操作将永久删除[" + data.version + "]版本, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delVersion(data.id).then(resp => {
              if (resp) {
                this.initVersion();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddVersionView() {
      this.version.id = null;
      this.version.source = null;
      this.version.sources = null;
      this.version.version = '';
      this.version.remark = '';
      this.version.isUpdate = false;

      this.dialogVisible = true;
      this.title = "添加版本";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 500;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateVersionView(data) {
      // 拷贝
      this.version = Object.assign({}, data);
      this.dialogVisible = true;
      this.title = "编辑版本";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 500;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 内容赋值
        editor.txt.html("");
        editor.txt.html(this.version.remark);
      })
    },
    closeDialog() {
      this.$refs["versionForm"].resetFields();
    },
    doVersion() {
      if (this.version.id) {
        // 编辑
        this.$refs["versionForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.version.remark = editor.txt.html();
            upVersion(this.version).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initVersion();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["versionForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.version.remark = editor.txt.html();
            addVersion(this.version).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initVersion();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    filesUploadSuccess(res) {
      this.$message.success("上传成功了哦 可以进行添加了ヾ|≧_≦|〃");
      this.urlTitle = "查看下载地址";
      this.url = res.data;
      this.urlDialogVisible = true;
      this.version.url = res.data;
    },
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label{
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>