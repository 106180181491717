import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 获取所有番剧类型
export function animeTypes() {
  return getRequest("/record/anime/getAnimeTypeList");
}

// 番剧记录列表
export function animePageData(params = {}) {
  return postRequest("/record/anime/getPageData", params);
}

// 删除番剧
export function delAnime(id) {
  return deleteRequest("/record/anime/" + id);
}

// 添加番剧
export function addAnime(params = {}) {
  return postRequest("/record/anime", params);
}

// 更新番剧
export function upAnime(params = {}) {
  return putRequest("/record/anime", params);
}

// 删除番剧类型
export function delAnimeType(id) {
  return deleteRequest("/record/anime/deleteAnimeType/" + id);
}

// 更新番剧类型
export function upAnimeType(params = {}) {
  return putRequest("/record/anime/updateAnimeType", params);
}

// 新增番剧类型
export function addAnimeType(params = {}) {
  return postRequest("/record/anime/addAnimeType", params);
}
