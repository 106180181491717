import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// openai
export function openai(params = {}) {
  return postRequest("/query/openai", params);
}
