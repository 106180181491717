import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "@/utils/api.js";

// 获取所有原神武器
export function genshinArms() {
    return getRequest("/genshin/arms/getAll");
}

// 原神武器记录列表
export function genshinArmPageData(params = {}) {
    return postRequest("/genshin/arms/getPageData", params);
}

// 删除原神武器
export function delGenshinArm(id) {
    return deleteRequest("/genshin/arms/" + id);
}

// 添加原神武器
export function addGenshinArm(params = {}) {
    return postRequest("/genshin/arms", params);
}

// 更新原神武器
export function upGenshinArm(params = {}) {
    return putRequest("/genshin/arms", params);
}