import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "@/utils/api.js";

// 获取所有原神材料类型
export function genshinMaterialsTypes() {
    return getRequest("/genshin/materials/materialsType");
}

// 原神材料记录列表
export function genshinMaterialsPageData(params = {}) {
    return postRequest("/genshin/materials/getPageData", params);
}

// 删除原神材料
export function delGenshinMaterials(id) {
    return deleteRequest("/genshin/materials/" + id);
}

// 添加原神材料
export function addGenshinMaterials(params = {}) {
    return postRequest("/genshin/materials", params);
}

// 更新原神材料
export function upGenshinMaterials(params = {}) {
    return putRequest("/genshin/materials", params);
}