import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 小人国国家列表
export function xrgCountryPageData(params = {}) {
  return postRequest("/xrg/country/getPageData", params);
}

// 小人国全部角色信息
export function xrgRoles() {
  return getRequest("/xrg/country/roles");
}
