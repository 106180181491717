import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 登录
export function login(params = {}) {
  return postRequest("/login", params);
}

// 获取验证码
export function sendCode(params = {}) {
  return postRequest("/app/send/code", params);
}

// 注册
export function register(params = {}) {
  return postRequest("/register", params);
}

// 注销
export function logout() {
  return postRequest("/logout");
}

// app版本查询
export function appVersion(params = {}) {
  return postRequest("/query/searchAppVersion", params);
}

// 重置密码
export function resetPass(params = {}) {
  return postRequest("/resetPass", params);
}