const getters = {
    currentRole: state => state.user.currentRole,
    stars: state => state.general.stars,
    steps: state => state.general.steps,
    sexs: state => state.general.sexs,
    starRailStars: state => state.star.starRailStars,
    starRailSteps: state => state.star.starRailSteps,
    starRailAttributes: state => state.star.starRailAttributes,
    starRailRelicTypes: state => state.star.starRailRelicTypes,
    starRailDirections: state => state.star.starRailDirections,
    hottaTypes: state => state.hotta.hottaTypes,
    hottaStars: state => state.hotta.hottaStars,
    arknightsLevelTypes: state => state.arknights.arknightsLevelTypes,
    arknightsMasteryTypes: state => state.arknights.arknightsMasteryTypes,
    arknightsPotentials: state => state.arknights.arknightsPotentials,
    arknightsStars: state => state.arknights.arknightsStars,
    arknightsMaterialsProbabilities: state => state.arknights.arknightsMaterialsProbabilities,
    permissionRecordType: state => state.permission.permissionRecordType,
    accountCategories: state => state.person.accountCategories,
    plAttribute: state => state.pl.plAttribute,
    plWork: state => state.pl.plWork,
    plAppetite: state => state.pl.plAppetite,
    gameTypes: state => state.record.gameTypes,
    sources: state => state.sys.sources,
    entries: state => state.genshin.entries,
    attributes: state => state.genshin.attributes,
    armsTypes: state => state.genshin.armsTypes,
    genshinAddresses: state => state.genshin.genshinAddresses,
}

export default getters