import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "@/utils/api.js";

// 获取所有明日方舟角色信息
export function arknightsRoles() {
    return getRequest("/arknights/role/getAll");
}

// 明日方舟角色记录列表
export function arknightsRolePageData(params = {}) {
    return postRequest("/arknights/role/getPageData", params);
}

// 删除明日方舟角色
export function delArknightsRole(id) {
    return deleteRequest("/arknights/role/" + id);
}

// 添加明日方舟角色
export function addArknightsRole(params = {}) {
    return postRequest("/arknights/role", params);
}

// 更新明日方舟角色
export function upArknightsRole(params = {}) {
    return putRequest("/arknights/role", params);
}

// 明日方舟角色图表
export function arknightsRoleCount() {
    return getRequest("/arknights/role/count");
}