import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";
import {downloadRequest} from "@/utils/download";

// 抽奖
export function lottery() {
  return getRequest("/activity/luckdraw");
}

// 获取抽奖配置
export function luckdrawConfig() {
  return getRequest("/activity/luckdraw/getConfig");
}

// 抽奖记录
export function luckdrawPageData(params = {}) {
  return postRequest("/activity/luckdraw/getPageData", params);
}

// 删除抽奖记录
export function delLuckdraw(id) {
  return deleteRequest("/activity/luckdraw/" + id);
}

// 查看兑换码
export function luckdrawCode(id) {
  return getRequest("/activity/luckdraw/getCode/" + id);
}

// 兑现
export function luckdrawCash(params = {}) {
  return postRequest("/activity/luckdraw/cash", params);
}

// 抽奖配置组列表
export function luckdrawConfigList() {
  return getRequest("/activity/luckdraw/getConfigList");
}

// 删除抽奖配置组
export function delLuckdrawConfig(id) {
  return deleteRequest("/activity/luckdraw/configs/" + id);
}

// 更新抽奖配置组
export function upLuckdrawConfig(params = {}) {
  return putRequest("/activity/luckdraw/updateConfigs", params);
}

// 新增抽奖配置组
export function addLuckdrawConfig(params = {}) {
  return postRequest("/activity/luckdraw/addConfigs", params);
}

// 获取抽奖次数
export function luckdrawNum(params = {}) {
  return postRequest("/activity/luckdraw/getNum", params);
}

// 抽奖次数配置更新
export function luckdrawNumConfig(params = {}) {
  return postRequest("/activity/luckdraw/numConfigs", params);
}

// 抽奖记录列表导出
export function luckdrawRecordExport() {
  return downloadRequest("/activity/luckdraw/export");
}