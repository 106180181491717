import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 帕鲁列表
export function paluPageData(params = {}) {
  return postRequest("/palu/info/getPageData", params);
}
