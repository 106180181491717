<template>
  <div>
    <div>
      <div>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchPermissionRelevance.name = ''; searchPermissionRelevance.type = '';">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchPermissionRelevance.currentPage = 1; searchPermissionRelevance.size = 10; initPermissionRelevance()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddPermissionRelevanceView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('PermissionRelevance') !== -1"
        >
          添加审批关联
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="permissionRelevanceData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="申请人"
            align="center"
            prop="roleName"
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('PermissionRelevance') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdatePermissionRelevanceView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('PermissionRelevance') !== -1" size="mini" style="padding: 3px" type="danger" @click="deletePermissionRelevance(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchPermissionRelevance.currentPage"
          :page-size="searchPermissionRelevance.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="600px">
      <el-form ref="permissionRelevanceForm" :model="permissionRelevance" :rules="rules">
        <table>
          <tr v-if="permissionRelevance.id">
            <el-form-item label="申请人" prop="roleId">
              <el-select
                  v-model="permissionRelevance.roleId"
                  size="mini"
                  style="width: 400px;margin-right: 20px"
                  placeholder="申请人"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.realname"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr v-else>
            <el-form-item label="申请人" prop="roleId">
              <el-select
                  v-model="permissionRelevance.roleId"
                  size="mini"
                  style="width: 400px;margin-right: 20px"
                  placeholder="申请人"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in unBindRoles"
                    :key="item.id"
                    :label="item.realname"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="审批人" prop="parentRoleIds">
              <el-select
                  v-model="permissionRelevance.parentRoleIds"
                  size="mini"
                  multiple
                  style="width: 400px;"
                  placeholder="审批人"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.realname"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doPermissionRelevance">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
import store from "@/store";
import {permissRelevanceUnBindRoles, delPermissRelevance, addPermissRelevance,
  permissRelevancePageData, upPermissRelevance} from "@/api/permission/relevance";
import {roles} from "@/api/sys/role";

export default {
  name: "PermissionRelevance",
  data() {
    return {
      permissionRelevanceData: [],
      loading: false,
      unBindRoles: [],
      roles: [],
      searchPermissionRelevance: {
        currentPage: 1,
        size: 10,
      },
      permissionRelevance: {
        id: null,
        roleId: null,
        parentRoleIds: []
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        roleId: [{required: true, message: "请选择申请人", trigger: "change"}],
        parentRoleIds: [{required: true, message: "请选择审批人", trigger: "change"}],
      },
      roleButton: store.getters.currentRole,
    }
  },
  computed: {

  },
  mounted() {
    this.getUnBindRoles();
    this.initRoles();
    this.initPermissionRelevance();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initRoles() {
      roles().then(resp => {
        if (resp) {
          this.roles = resp;
        }
      })
    },
    getUnBindRoles() {
      permissRelevanceUnBindRoles().then(resp => {
        if (resp) {
          this.unBindRoles = resp;
        }
      })
    },
    initPermissionRelevance() {
      this.loading = true;
      permissRelevancePageData(this.searchPermissionRelevance).then(resp => {
        this.loading = false;
        if (resp) {
          this.permissionRelevanceData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchPermissionRelevance.currentPage = page;
      this.initPermissionRelevance();
    },
    sizeChange(size) {
      this.searchPermissionRelevance.size = size;
      this.initPermissionRelevance();
    },
    deletePermissionRelevance(data) {
      this.$confirm(
          "此操作将永久删除[" + data.roleName + "]申请人, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delPermissRelevance(data.roleId).then(resp => {
              if (resp) {
                this.initPermissionRelevance();
                this.getUnBindRoles();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddPermissionRelevanceView() {
      this.permissionRelevance = {
        id: null,
        roleId: null,
        parentRoleIds: []
      };
      this.dialogVisible = true;
      this.title = "添加审批关联";
    },
    showUpdatePermissionRelevanceView(data) {
      // 拷贝
      this.permissionRelevance = Object.assign({}, data);
      this.dialogVisible = true;
      this.title = "编辑审批关联";
    },
    closeDialog() {
      this.$refs["permissionRelevanceForm"].resetFields();
    },
    doPermissionRelevance() {
      if (this.permissionRelevance.id) {
        // 编辑
        this.$refs["permissionRelevanceForm"].validate((valid) => {
          if (valid) {
            upPermissRelevance(this.permissionRelevance).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initPermissionRelevance();
                this.getUnBindRoles();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["permissionRelevanceForm"].validate((valid) => {
          if (valid) {
            addPermissRelevance(this.permissionRelevance).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initPermissionRelevance();
                this.getUnBindRoles();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label{
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>