const hotta = {
    state: {
        hottaTypes: ['N', 'R', 'SR', 'SSR'],
        hottaStars: [0, 1, 2, 3, 4, 5, 6],
    },
    mutations: {

    },
    actions: {}
}

export default hotta