import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 小人国角色列表
export function xrgRolePageData(params = {}) {
  return postRequest("/xrg/role/getPageData", params);
}

// 小人国全部地区信息
export function xrgAreas() {
  return getRequest("/xrg/role/areas");
}

// 小人国全部等级信息
export function xrgLevels() {
  return getRequest("/xrg/role/levels");
}

// 根据小人国国家获取地区信息
export function xrgAreasByCountry(id) {
  return getRequest("/xrg/role/areasByCountry/" + id);
}

// 小人国角色图表信息
export function xrgRoleCount() {
  return getRequest("/xrg/role/count");
}
