<template>
  <div style="padding: 10px">
    <div style="margin-top: 15px">
      <el-select
          v-model="param.roleId"
          size="mini"
          style="width: 200px;margin-right: 20px"
          placeholder="角色"
          clearable
          filterable
          @change="expenditureDrawLine(); incomeDrawLine()"
      >
        <el-option
            v-for="item in roles"
            :key="item.id"
            :label="item.realname"
            :value="item.id"
        >
        </el-option>
      </el-select>

      <el-select
          v-model="param.accountBookId"
          size="mini"
          style="width: 200px;margin-right: 20px"
          placeholder="账本"
          clearable
          filterable
          @change="expenditureDrawLine(); incomeDrawLine()"
      >
        <el-option
            v-for="item in accountBooks"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>

      <el-date-picker
          style="margin-right: 20px"
          size="mini"
          :picker-options="pickerOptions"
          v-model="time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="expenditureDrawLine(); incomeDrawLine()"
      >
      </el-date-picker>
    </div>

    <el-card style="margin-top: 15px">
      <div id="expenditureChart" :style="{ width: '400px', height: '600px', display: 'inline-block' }"></div>
      <div id="incomeChart" :style="{ width: '400px', height: '600px', display: 'inline-block' }"></div>
    </el-card>
  </div>
</template>

<script>
import {roles} from "@/api/sys/role";
import {
  accountBooks,
  accountExpenditureCount,
  accountIncomeCount,
  accountTypesByCategory
} from "@/api/person/accountRecord";
import {AccountRecordCategoryEnum} from "@/utils/constants";

export default {
  name: "AccountEcharts",
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '今日',
          onClick(picker) {
            var y = new Date().getFullYear();
            var m = new Date().getMonth();
            var d = new Date().getDate();
            const end = new Date(y + "-" + (m + 1) + "-" + d + " 23:59:59");
            const start = new Date(y + "-" + (m + 1) + "-" + d + " 00:00:00");
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '昨日',
          onClick(picker) {
            var today = new Date();
            var yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
            var y = yesterday.getFullYear();
            var m = yesterday.getMonth();
            var d = yesterday.getDate();
            const end = new Date(y + "-" + (m + 1) + "-" + d + " 23:59:59");
            const start = new Date(y + "-" + (m + 1) + "-" + d + " 00:00:00");
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            var today = new Date();
            var y = today.getFullYear();
            var m = today.getMonth();
            var d = today.getDate();
            var weekday = new Date(today.getTime() - 3600 * 1000 * 24 * 7);
            var wy = weekday.getFullYear();
            var wm = weekday.getMonth();
            var wd = weekday.getDate();
            const end = new Date(y + "-" + (m + 1) + "-" + d + " 23:59:59");
            const start = new Date(wy + "-" + (wm + 1) + "-" + wd + " 00:00:00");
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            var today = new Date();
            var y = today.getFullYear();
            var m = today.getMonth();
            var d = today.getDate();
            var monthday = new Date(today.getTime() - 3600 * 1000 * 24 * 30);
            var my = monthday.getFullYear();
            var mm = monthday.getMonth();
            var md = monthday.getDate();
            const end = new Date(y + "-" + (m + 1) + "-" + d + " 23:59:59");
            const start = new Date(my + "-" + (mm + 1) + "-" + md + " 00:00:00");
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '15号月度',
          onClick(picker) {
            var today = new Date();
            var y = today.getFullYear();
            var m = today.getMonth();
            var d = today.getDate();
            var start;
            var end;
            if (d >= 15) {
              start = new Date(y + "-" + (m + 1) + "-15 00:00:00");
              end = new Date(y + "-" + (m + 2) + "-15 23:59:59");
            } else {
              start = new Date(y + "-" + (m) + "-15 00:00:00");
              end = new Date(y + "-" + (m + 1) + "-15 23:59:59");
            }
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      roles: [],
      accountBooks: [],
      time: [],
      param: {
        roleId: null,
        accountBookId: null,
        startTime: null,
        endTime: null
      },
      accountTypes: [],
    };
  },
  mounted() {
    this.initRoles();
    this.initAccountBooks();
    this.initAccountTypes();
    this.expenditureDrawLine();
    this.incomeDrawLine();
  },
  methods: {
    initRoles() {
      roles().then(resp => {
        if (resp) {
          this.roles = resp;
        }
      })
    },
    initAccountBooks() {
      accountBooks().then(resp => {
        if (resp) {
          this.accountBooks = resp;
        }
      })
    },
    async initAccountTypes() {
      // 支出类型
      let expenditureTypes = await accountTypesByCategory(1);
      // 收入类型
      let incomeTypes = await accountTypesByCategory(2);
      if (expenditureTypes) {
        expenditureTypes.forEach(expenditureType => {
          this.accountTypes.push(expenditureType);
        });
      }
      if (incomeTypes) {
        incomeTypes.forEach(incomeType => {
          this.accountTypes.push(incomeType);
        });
      }
    },
    expenditureDrawLine() {
      // 基于准备好的dom，初始化echarts实例
      let expenditureChart = this.$root.echarts.init(document.getElementById("expenditureChart"));
      let option = {
        title: {
          text: "支出金额比例统计图",
          subtext: "金额数据",
          left: "center",
        },
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: 'bottom',
          trigger: "item",
        },
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            dataView: {show: true, readOnly: false},
            restore: {show: true},
            saveAsImage: {show: true},
          },
        },
        series: [
          {
            name: "支出金额",
            type: "pie",
            radius: "55%",
            center: ["50%", "45%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: [],
          },
        ],
      };

      if (this.time !== null && this.time.length === 2) {
        this.param.startTime = this.time[0];
        this.param.endTime = this.time[1];
      } else {
        this.param.startTime = null;
        this.param.endTime = null;
      }

      accountExpenditureCount(this.param).then((res) => {
        res.forEach((item) => {
          option.series[0].data.push({name: item.name, value: item.amt});
        });
        // 绘制图表
        expenditureChart.setOption(option);
        expenditureChart.on('click', this.expenditureChartClickFunc);
      });
    },
    incomeDrawLine() {
      // 基于准备好的dom，初始化echarts实例
      let incomeChart = this.$root.echarts.init(document.getElementById("incomeChart"));
      let option = {
        title: {
          text: "收入金额比例统计图",
          subtext: "金额数据",
          left: "center",
        },
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: 'bottom',
          trigger: "item",
        },
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            dataView: {show: true, readOnly: false},
            restore: {show: true},
            saveAsImage: {show: true},
          },
        },
        series: [
          {
            name: "收入金额",
            type: "pie",
            radius: "55%",
            center: ["50%", "45%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: [],
          },
        ],
      };

      if (this.time !== null && this.time.length === 2) {
        this.param.startTime = this.time[0];
        this.param.endTime = this.time[1];
      } else {
        this.param.startTime = null;
        this.param.endTime = null;
      }

      accountIncomeCount(this.param).then((res) => {
        res.forEach((item) => {
          option.series[0].data.push({name: item.name, value: item.amt});
        });
        // 绘制图表
        incomeChart.setOption(option);
        incomeChart.on('click', this.incomeChartClickFunc);
      });
    },
    expenditureChartClickFunc(param) {
      let name = param.data.name;
      let accountCategoryId;
      this.accountTypes.forEach((item) => {
        if (name === item.typeName && AccountRecordCategoryEnum.EXPENDITURE === item.category) {
          accountCategoryId = item.id;
        }
      });
      // 跳转记录页面并给过去查询参数
      this.$router.push({
        path: '/person/accountRecord',
        query: {
          accountCategoryId: accountCategoryId,
          accountCategory: AccountRecordCategoryEnum.EXPENDITURE,
          startTime: this.param.startTime,
          endTime: this.param.endTime,
          roleId: this.param.roleId,
          bookId: this.param.bookId
        }
      });
    },
    incomeChartClickFunc(param) {
      let name = param.data.name;
      let accountCategoryId;
      this.accountTypes.forEach((item) => {
        if (name === item.typeName && AccountRecordCategoryEnum.INCOME === item.category) {
          accountCategoryId = item.id;
        }
      });
      // 跳转记录页面并给过去查询参数
      this.$router.push({
        path: '/person/accountRecord',
        query: {
          accountCategoryId: accountCategoryId,
          accountCategory: AccountRecordCategoryEnum.INCOME,
          startTime: this.param.startTime,
          endTime: this.param.endTime,
          roleId: this.param.roleId,
          bookId: this.param.bookId
        }
      });
    }
  },
}
</script>

<style>
</style>