<template>
  <div>
    <el-row class="openai">
      <el-container>
        <el-header class="title">
          <p>OPENAI</p>
        </el-header>
        <el-main>
          <el-card class="box-card">
            <div v-for="(entry, index) in chatHistory" :key="index" :class="[entry.type]">
              <pre>{{ entry.message }}</pre>
            </div>
            <el-input autosize class="message" placeholder="输入你的问题然后回车" v-model="message" clearable
                      size="large" :disabled="isdisabled" @keydown.enter.native="query" />
          </el-card>
        </el-main>
      </el-container>
    </el-row>
  </div>
</template>

<script>
import {openai} from "@/api/other/query";

export default {
  name: "Query",
  data() {
    return {
      isdisabled: false,
      message: '',
      chatHistory: [],
      Message: null
    }
  },
  methods: {
    query() {
      this.isdisabled = true
      if (!this.message) return;
      openai({message: this.message}).then(res => {
        this.chatHistory.push({
          type: 'self',
          message: this.message,
        });

        this.chatHistory.push({
          type: 'ai',
          message: res["answer"],
        });
        this.message = '';
        this.isdisabled = false
      })
    }
  },
}
</script>

<style>
.openai {
  height: inherit;
  overflow: auto;
  background-color: #db6fd2;
  background-size: 100% 100%;
}

.title {
  color: #fff;
  text-align: center;
}

.title .p {
  font-size: 48px;
  font-weight: bold;
}

.box-card {
  font-size: 18px;
}

.box-card .self {
  margin: 5px;
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(237, 237, 237);
}

.box-card .ai {
  margin: 5px;
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(237, 237, 237);
}

.box-card pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -webkit-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.box-card .message {
  position: relative;
  bottom: 0px;
}

.box-card .answer {
  background-color: #f1f1f1;
  border-radius: 3px;
  font-size: 16px;
  margin-top: 10px;
}
</style>
