<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="国家名称"
            v-model="searchContry.name"
            clearable
            @keydown.enter.native="searchContry.currentPage = 1;searchContry.size = 10 ;initCountries()"
        ></el-input>
        <el-select
            v-model="searchContry.roleId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="国家君主"
            clearable
            filterable
            @change="searchContry.currentPage = 1;searchContry.size = 10 ;initCountries()"
        >
          <el-option
              v-for="item in roles"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchContry.name = '';searchContry.roleId = null;">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchContry.currentPage = 1;searchContry.size = 10 ;initCountries()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddCountryView"
            v-if="role.buttonLevelComponents !== null && role.buttonLevelComponents.indexOf('Country') !== -1"
        >
          添加国家
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="countries"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            prop="name"
            label="国家名称"
        >
        </el-table-column>
        <el-table-column
            prop="roleName"
            label="国家君主"
        >
        </el-table-column>
        <el-table-column label="国家备注">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.remark && commonApi.toText(scope.row.remark).length > 15" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsis(scope.row.remark)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="commonApi.toText(scope.row.remark)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
          :current-page="searchContry.currentPage"
          :page-size="searchContry.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
import store from "@/store";
import {xrgRoles, xrgCountryPageData} from "@/api/xrg/country";

export default {
  name: "Country",
  data() {
    return {
      countries: [],
      loading: false,
      total: 0,
      searchContry: {
        name: "",
        roleId: null,
        currentPage: 1,
        size: 10,
      },
      roles: [],
      role: store.getters.currentRole,
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      let input = value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
      if (input.length > 15) {
        return input.slice(0, 15) + '...';
      } else {
        return value;
      }
    }
  },
  computed: {

  },
  mounted() {
    this.initCountries();
    this.initRoles();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initCountries() {
      this.loading = true;
      xrgCountryPageData(this.searchContry).then(resp => {
        this.loading = false;
        if (resp) {
          this.total = resp.total;
          this.countries = resp.data;
        }
      })
    },
    currentChange(page) {
      this.searchContry.currentPage = page;
      this.initCountries();
    },
    sizeChange(size) {
      this.searchContry.size = size;
      this.initCountries();
    },
    initRoles() {
      xrgRoles().then(resp => {
        if (resp) {
          this.roles = resp;
        }
      })
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>