<template>
  <div>
    <div style="margin-top: 15px">
      <div>
        <el-select
            v-model="searchAnime.typeId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="番剧类型"
            clearable
            filterable
            @change="searchAnime.currentPage = 1; searchAnime.size = 10; initAnime()"
        >
          <el-option
              v-for="item in animeTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="番剧名称"
            v-model="searchAnime.name"
            clearable
            @keydown.enter.native="searchAnime.currentPage = 1; searchAnime.size = 10; initAnime()"
        ></el-input>
        <el-select
            v-model="searchAnime.isWall"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="是否翻墙"
            clearable
            filterable
            @change="searchAnime.currentPage = 1; searchAnime.size = 10; initAnime()"
        >
          <el-option label="需要翻墙" :value="true">需要翻墙</el-option>
          <el-option label="不需要翻墙" :value="false">不需要翻墙</el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchAnime.name = ''; searchAnime.isWall = null; searchAnime.typeId = null">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchAnime.currentPage = 1; searchAnime.size = 10; initAnime()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddAnimeView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Anime') !== -1"
        >
          添加番剧
        </el-button>
        <el-button type="danger" size="mini" @click="showTypeView">番剧类型配置</el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="animeData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="番剧名称"
            align="center"
            prop="name"
        ></el-table-column>
        <el-table-column
            label="番剧类型"
            align="center"
            prop="typeName"
        ></el-table-column>
        <el-table-column label="番剧地址" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.url === null || scope.row.url === '' || scope.row.url === undefined">-</span>
            <a target="_blank" v-else :href="scope.row.url">{{ scope.row.name }}</a>
          </template>
        </el-table-column>
        <el-table-column label="是否需要翻墙" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.isWall">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="250"
            label="番剧描述"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.remark && scope.row.remark.length <= 25" v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
            <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 25" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-if="!scope.row.remark">-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            width="200"
            align="center"
            prop="createTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="更新时间"
            width="200"
            align="center"
            prop="updateTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Anime') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateAnimeView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Anime') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteAnime(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
          :current-page="searchAnime.currentPage"
          :page-size="searchAnime.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="800px">
      <el-form ref="animeForm" :model="anime" :rules="rules">
        <table>
          <tr>
            <el-form-item label="番剧类型">
              <el-select
                  v-model="anime.typeId"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="番剧类型"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in animeTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="番剧名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="anime.name"
                  placeholder="请输入番剧名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="番剧地址" prop="url">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="anime.url"
                  placeholder="请输入番剧名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="是否需要翻墙">
              <el-switch
                  v-model="anime.isWall"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  style="padding-top: 6px">
              </el-switch>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="番剧描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doAnime">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 番剧类型 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="typeTitle"
        @close="closeTypeDialog()"
        :visible.sync="typeDialogVisible"
        width="400px">
      <el-form ref="typeForm" :model="typeData" :rules="typeRules">
        <table>
          <tr>
            <el-form-item label="类型编号">
              <el-select
                  v-model="typeData.id"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="番剧类型编号"
                  clearable
                  filterable
                  @change="initTypeInputData()"
              >
                <el-option
                    v-for="item in animeTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="类型名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="typeData.name"
                  placeholder="请输入番剧类型名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="类型描述">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 200px"
                  v-model="typeData.remark"
                  placeholder="请输入番剧类型描述"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="typeDialogVisible = false">取 消</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Anime') !== -1 && typeData.id !== null" type="danger" @click="deleteAnimeType">删 除</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Anime') !== -1" type="primary" @click="doAnimeType">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
// wangeditor富文本
import E from "wangeditor"
import store from "@/store";
import {
  addAnime, addAnimeType, animePageData, animeTypes,
  delAnime, delAnimeType, upAnime, upAnimeType
} from "@/api/record/anime";
let editor;

export default {
  name: "Anime",
  data() {
    return {
      animeData: [],
      loading: false,
      searchAnime: {
        name: '',
        isWall: null,
        typeId: null,
        currentPage: 1,
        size: 10,
      },
      anime: {
        id: null,
        name: '',
        url: '',
        isWall: false,
        typeId: null
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入番剧名称", trigger: "blur"}],
      },
      animeTypes: [],
      typeTitle: "",
      typeDialogVisible: false,
      typeData: {
        id: null,
        name: "",
        remark: ""
      },
      typeRules: {
        name: [{required: true, message: "请输入番剧类型名称", trigger: "blur"}],
      },
      roleButton: store.getters.currentRole,
    }
  },
  computed: {

  },
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initAnime();
    this.initAnimeType();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initAnime() {
      this.loading = true;
      animePageData(this.searchAnime).then(resp => {
        this.loading = false;
        if (resp) {
          this.animeData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchAnime.currentPage = page;
      this.initAnime();
    },
    sizeChange(size) {
      this.searchAnime.size = size;
      this.initAnime();
    },
    deleteAnime(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]番剧, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delAnime(data.id).then(resp => {
              if (resp) {
                this.initAnime();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddAnimeView() {
      this.anime = {
        id: null,
        name: '',
        url: '',
        isWall: false,
        typeId: null
      };
      this.dialogVisible = true;
      this.title = "添加番剧";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateAnimeView(data) {
      // 拷贝
      this.anime = Object.assign({}, data);
      this.dialogVisible = true;
      this.title = "编辑番剧";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 内容赋值
        editor.txt.html("");
        editor.txt.html(this.anime.remark);
      })
    },
    closeDialog() {
      this.$refs["animeForm"].resetFields();
    },
    doAnime() {
      if (this.anime.id) {
        // 编辑
        this.$refs["animeForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.anime.remark = editor.txt.html();
            upAnime(this.anime).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initAnime();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["animeForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.anime.remark = editor.txt.html();
            addAnime(this.anime).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initAnime();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    initAnimeType() {
      animeTypes().then(resp => {
        if (resp) {
          this.animeTypes = resp;
        }
      })
    },
    showTypeView() {
      this.initAnimeType();
      this.typeTitle = '番剧类型配置';
      this.typeDialogVisible = true;
    },
    closeTypeDialog() {
      this.typeData.id = null;
      this.typeData.name = "";
      this.typeData.remark = "";
    },
    initTypeInputData() {
      let id = this.typeData.id;

      // 如果是清除就恢复最初数据
      if (id === '') {
        this.closeTypeDialog();
        return;
      }

      this.animeTypes.forEach((item) => {
        if (String(item.id) === String(id)) {
          this.typeData.id = item.id;
          this.typeData.name = item.name;
          this.typeData.remark = item.remark;
        }
      })
    },
    deleteAnimeType() {
      this.$confirm(
        "此操作将永久删除[" + this.typeData.name + "]的番剧类型, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          delAnimeType(this.typeData.id).then((resp) => {
            if (resp) {
              this.typeDialogVisible = false;
              this.initAnimeType();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    doAnimeType() {
      if (this.typeData.id) {
        // 编辑
        this.$refs["typeForm"].validate((valid) => {
          if (valid) {
            upAnimeType(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false;
                this.initAnimeType();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["typeForm"].validate((valid) => {
          if (valid) {
            addAnimeType(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false;
                this.initAnimeType();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label{
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
