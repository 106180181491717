const permission = {
    state: {
        permissionRecordType: [{"code": 1, "desc": "申请记录"}, {"code": 2, "desc": "审批记录"}],
    },
    mutations: {

    },
    actions: {}
}

export default permission