<template>
  <div>
    <div>
      <div>
        <el-select
            v-model="searchMessageNotification.roleId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="接收人"
            clearable
            filterable
            @change="searchMessageNotification.currentPage = 1; searchMessageNotification.size = 10; initMessageNotification()"
        >
          <el-option
              v-for="item in roles"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="消息标题"
            v-model="searchMessageNotification.title"
            clearable
            @keydown.enter.native="searchMessageNotification.currentPage = 1; searchMessageNotification.size = 10; initMessageNotification()"
        ></el-input>
        <el-select
            v-model="searchMessageNotification.type"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="消息类型"
            clearable
            filterable
            @change="searchMessageNotification.currentPage = 1;searchMessageNotification.size = 10 ;initMessageNotification()">
          <el-option label="弹窗通知消息" value="1"></el-option>
          <el-option label="人工通知消息" value="2"></el-option>
        </el-select>
        <el-select
            v-model="searchMessageNotification.status"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="是否已读"
            clearable
            filterable
            @change="searchMessageNotification.currentPage = 1;searchMessageNotification.size = 10 ;initMessageNotification()">
          <el-option label="是" value="true"></el-option>
          <el-option label="否" value="false"></el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetSearch">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchMessageNotification.currentPage = 1; searchMessageNotification.size = 10; initMessageNotification()"
        >
          搜索
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="messageNotificationData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="接收人"
            width="200"
            align="center"
            prop="roleName"
        ></el-table-column>
        <el-table-column
            label="消息标题"
            width="200"
            align="center"
            prop="title"
        ></el-table-column>
        <el-table-column
            label="消息类型"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.typeName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="是否已读"
            width="100"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="消息内容" align="center">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.content" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.content.replace(/\n/gm, '<br>')" slot="content">{{scope.row.content}}</span>
              <!--<span>{{scope.row.context | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            align="center"
            width="180"
            prop="createTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="120"
        >
          <template slot-scope="scope">
            <el-button size="mini" style="padding: 3px" type="warning" @click="readMessage(scope.row)">查看详情</el-button>
            <el-button size="mini" style="padding: 3px" type="danger" @click="deleteMessageNotification(scope.row)">清理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchMessageNotification.currentPage"
          :page-size="searchMessageNotification.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="600px">
      <el-form ref="messageNotificationForm" :model="messageNotification" :rules="rules">
        <table>
          <tr>
            <el-form-item label="接收人">
              <el-input
                  size="mini"
                  style="width: 400px"
                  disabled
                  v-model="messageNotification.roleName"
                  placeholder="请输入接收人"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="消息标题">
              <el-input
                  size="mini"
                  style="width: 400px"
                  disabled
                  v-model="messageNotification.title"
                  placeholder="请输入消息标题"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="消息内容">
              <el-input
                  type="textarea"
                  :rows="10"
                  disabled
                  size="mini"
                  style="width: 400px"
                  v-model="messageNotification.content"
                  placeholder="请输入消息内容"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
import store from "@/store";
import {roles} from "@/api/sys/role";
import {
  delPrMsgNotification,
  prMsgNotificationDetail,
  prMsgNotificationPageData
} from "@/api/person/messageNotification";

export default {
  name: "PersonMessageNotification",
  data() {
    return {
      messageNotificationData: [],
      loading: false,
      searchMessageNotification: {
        title: '',
        type: null,
        status: null,
        enabled: true,
        roleId: null,
        currentPage: 1,
        size: 10,
      },
      total: 0,
      messageNotification: {
        id: null,
        title: '',
        content: '',
        status: false,
        type: 2,
        enabled: true,
        roleIds: null
      },
      dialogVisible: false,
      title: '',
      roles: null,
      rules: null,
      roleButton: store.getters.currentRole,
    }
  },
  computed: {

  },
  mounted() {
    this.initMessageNotification();
    this.initRoles();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initRoles() {
      roles().then(resp => {
        if (resp) {
          this.roles = resp;
        }
      })
    },
    initMessageNotification() {
      this.loading = true;
      prMsgNotificationPageData(this.searchMessageNotification).then(resp => {
        this.loading = false;
        if (resp) {
          this.messageNotificationData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchMessageNotification.currentPage = page;
      this.initMessageNotification();
    },
    sizeChange(size) {
      this.searchMessageNotification.size = size;
      this.initMessageNotification();
    },
    readMessage(data) {
      prMsgNotificationDetail(data.id).then(resp => {
        if (resp) {
          this.messageNotification = Object.assign({}, resp);
          this.dialogVisible = true;
          this.title = "消息详情";
        }
      })
    },
    deleteMessageNotification(data) {
      this.$confirm(
          "此操作将清理[" + data.title + "]消息, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delPrMsgNotification(data.id).then(resp => {
              if (resp) {
                this.initMessageNotification();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    resetSearch() {
      this.searchMessageNotification.title = '';
      this.searchMessageNotification.status = null;
      this.searchMessageNotification.type = null;
      this.searchMessageNotification.roleId = null;
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label{
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>