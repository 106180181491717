<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="材料名称"
            v-model="searchMaterials.name"
            clearable
            @keydown.enter.native="searchMaterials.currentPage = 1;searchMaterials.size = 10 ;initMaterials()"
        ></el-input>
        <el-select
            v-model="searchMaterials.type"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="材料类型"
            clearable
            filterable
            @change="searchMaterials.currentPage = 1;searchMaterials.size = 10 ;initMaterials()"
        >
          <el-option
              v-for="item in materialsType"
              :key="item.code"
              :label="item.desc"
              :value="item.code"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchMaterials.name = ''; searchMaterials.type = null;">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchMaterials.currentPage = 1;searchMaterials.size = 10 ;initMaterials()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddMaterialsView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('GenshinMaterials') !== -1"
        >
          添加材料
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="materialsData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            prop="name"
            align="center"
            label="材料名称"
        >
        </el-table-column>
        <el-table-column label="材料类型" align="center">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.typeName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="世界收集教程b站url">
          <template slot-scope="scope">
            <!--scope.row.type === 2 || scope.row.type === 3 || scope.row.type === 4-->
            <span v-if="!scope.row.url">-</span>
            <a v-else target="_blank" :href="scope.row.url">{{ scope.row.name }}</a>
          </template>
        </el-table-column>
        <el-table-column align="center" label="恢复时间(天)" sortable prop="recovery">
          <template slot-scope="scope">
            <!--scope.row.type === 2 || scope.row.type === 3-->
            <span v-if="!scope.row.recovery">-</span>
            <span v-else>{{ scope.row.recovery }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="消耗体力值" sortable prop="ps">
          <template slot-scope="scope">
            <span v-if="!scope.row.ps">-</span>
            <span v-else>{{ scope.row.ps }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否可以消耗树脂" align="center">
          <template slot-scope="scope">
            <!--<el-tag type="primary" v-if="scope.row.type === 1 || scope.row.type === 2 || scope.row.type === 4 || scope.row.type === 5">-</el-tag>-->
            <el-tag type="danger" v-if="scope.row.resin === false">不可以</el-tag>
            <el-tag type="success" v-else-if="scope.row.resin === true">可以</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('GenshinMaterials') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateMaterialsView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('GenshinMaterials') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteMaterials(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
          :current-page="searchMaterials.currentPage"
          :page-size="searchMaterials.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        width="425px">
      <el-form ref="materialsForm" :model="materials" :rules="rules" label-width="125px">
        <table>
          <tr>
            <el-form-item label="材料名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="materials.name"
                  placeholder="请输入材料名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="材料类型" prop="type">
              <el-select
                  v-model="materials.type"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="材料类型"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in materialsType"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <!--<tr>
            <el-form-item label="恢复时间(天)" v-if="materials.type === 1">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="materials.recovery"
                  placeholder="请输入恢复时间(天)"
              ></el-input>
            </el-form-item>
          </tr>-->
          <tr>
            <el-form-item label="世界收集教程url" v-if="materials.type === 1 || materials.type === 5">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="materials.url"
                  placeholder="请输入世界收集教程url"
              ></el-input>
            </el-form-item>
          </tr>
          <!--<tr>
            <el-form-item label="消耗体力值" v-if="materials.type === 2 || materials.type === 3 || materials.type === 4">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="materials.ps"
                  placeholder="请输入消耗体力值"
              ></el-input>
            </el-form-item>
          </tr>-->
          <!--<tr>
            <el-form-item label="是否可以消耗树脂" v-if="materials.type === 3">
              <el-switch
                  v-model="materials.resin"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  style="padding-top: 6px">
              </el-switch>
            </el-form-item>
          </tr>-->
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doMaterials">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import {addGenshinMaterials, delGenshinMaterials, upGenshinMaterials, genshinMaterialsPageData, genshinMaterialsTypes} from "@/api/genshin/materials";

export default {
  name: "GenshinMaterials",
  data() {
    return {
      materialsData: [],
      materialsType: [],
      loading: false,
      total: 0,
      searchMaterials: {
        name: "",
        type: null,
        currentPage: 1,
        size: 10,
      },
      materials: {
        id: null,
        name: "",
        type: null,
        recovery: "",
        url: "",
        ps: "",
        resin: false
      },
      dialogVisible: false,
      title: "",
      rules: {
        name: [{required: true, message: "请输入材料名称", trigger: "blur"}],
        type: [{required: true, message: "请选择材料类型", trigger: "blur"}]
      },
      roleButton: store.getters.currentRole,
    }
  },
  computed: {

  },
  mounted() {
    this.initMaterials();
    this.initMaterialsType();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initMaterialsType() {
      genshinMaterialsTypes().then(resp => {
        if (resp) {
          this.materialsType = resp;
        }
      })
    },
    initMaterials() {
      this.loading = true;
      genshinMaterialsPageData(this.searchMaterials).then(resp => {
        this.loading = false;
        if (resp) {
          this.total = resp.total;
          this.materialsData = resp.data;
        }
      })
    },
    currentChange(page) {
      this.searchMaterials.currentPage = page;
      this.initMaterials();
    },
    sizeChange(size) {
      this.searchMaterials.size = size;
      this.initMaterials();
    },
    deleteMaterials(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]材料, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delGenshinMaterials(data.id).then(resp => {
              if (resp) {
                this.initMaterials();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddMaterialsView(data) {
      this.materials = {
        id: null,
        name: "",
        type: null,
        recovery: "",
        url: "",
        ps: "",
        resin: false
      };
      this.title = "添加材料";
      this.dialogVisible = true;
    },
    showUpdateMaterialsView(data) {
      // 拷贝
      this.materials = Object.assign({}, data);
      this.title = "编辑材料";
      this.dialogVisible = true;
    },
    doDataHandler() {
      // 消耗体力值
      if (this.materials.type === 2) {
        this.materials.ps = "40";
      } else if (this.materials.type === 3) {
        this.materials.ps = "20";
      } else if (this.materials.type === 4) {
        this.materials.ps = "40";
      } else {
        this.materials.ps = "";
      }
      // 恢复时间(天)
      if (this.materials.type === 1) {
        this.materials.recovery = 2;
      } else if (this.materials.type === 5) {
        this.materials.recovery = 1;
      } else if (this.materials.type === 4) {
        this.materials.recovery = "7";
      } else {
        this.materials.recovery = "";
      }
      // 是否可以消耗树脂
      if (this.materials.type === 3) {
        this.materials.resin = true;
      } else {
        this.materials.resin = false;
      }
      // 世界收集教程url
      if (this.materials.type === 2 || this.materials.type === 3 || this.materials.type === 4) {
        this.materials.url = "";
      }
    },
    doMaterials() {
      this.doDataHandler();
      if (this.materials.id) {
        // 编辑
        this.$refs["materialsForm"].validate((valid) => {
          if (valid) {
            upGenshinMaterials(this.materials).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initMaterials();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["materialsForm"].validate((valid) => {
          if (valid) {
            addGenshinMaterials(this.materials).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initMaterials();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 125px;
  text-align: right;
}
</style>