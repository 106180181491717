const record = {
    state: {
        gameTypes: ['手机游戏', 'PC网络游戏', 'PC单机游戏'],
    },
    mutations: {

    },
    actions: {}
}

export default record