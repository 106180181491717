import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "@/utils/api.js";

// 获取所有的崩铁角色信息
export function starRailRoles() {
    return getRequest("/star-rail/role/getAll");
}

// 崩铁角色列表
export function starRailRolePageData(params = {}) {
    return postRequest("/star-rail/role/getPageData", params);
}

// 删除崩铁角色
export function delStarRailRole(id) {
    return deleteRequest("/star-rail/role/" + id);
}

// 添加崩铁角色
export function addStarRailRole(params = {}) {
    return postRequest("/star-rail/role", params);
}

// 更新崩铁角色
export function upStarRailRole(params = {}) {
    return putRequest("/star-rail/role", params);
}

// 崩铁角色图表
export function starRailRoleCount() {
    return getRequest("/star-rail/role/count");
}