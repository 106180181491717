import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "@/utils/api.js";

// 获取所有崩铁材料类型
export function starRailMaterialsTypes() {
    return getRequest("/star-rail/materials/getTypes");
}

// 崩铁材料列表
export function starRailMaterialsPageData(params = {}) {
    return postRequest("/star-rail/materials/getPageData", params);
}

// 删除崩铁材料
export function delStarRailMaterials(id) {
    return deleteRequest("/star-rail/materials/" + id);
}

// 添加崩铁材料
export function addStarRailMaterials(params = {}) {
    return postRequest("/star-rail/materials", params);
}

// 更新崩铁材料
export function upStarRailMaterials(params = {}) {
    return putRequest("/star-rail/materials", params);
}