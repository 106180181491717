import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 根据审批人获取申请人信息
export function permissRoleIdsByParentRoleId(id) {
  return getRequest(
    "/permission/permission-relevance/getRoleIdsByParentRoleId/" + id
  );
}

// 审批记录列表
export function permissRecordPageData(params = {}) {
  return postRequest("/permission/permission-record/getPageData", params);
}

// 删除审批记录
export function delPermissRecord(id) {
  return deleteRequest("/permission/permission-record/" + id);
}

// 根据申请人获取所有审批人的编号
export function permissParentRoleIdByRoleId(id) {
  return getRequest(
    "/permission/permission-relevance/getParentRoleIdByRoleId/" + id
  );
}

// 添加审批记录
export function addPermissRecord(params = {}) {
  return postRequest("/permission/permission-record", params);
}

// 编辑审批记录
export function upPermissRecord(params = {}) {
  return putRequest("/permission/permission-record", params);
}
