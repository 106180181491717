// ========== 枚举常量 ==========

/**
 * 全局通用状态枚举
 *
 * @type {{DISABLE: number, ENABLE: number}}
 */
export const CommonStatusEnum = {
    ENABLE: 0, // 开启
    DISABLE: 1 // 禁用
}

// 记录等级枚举
export const AccountRecordCategoryEnum = {
    EXPENDITURE: 1, // 支出
    INCOME: 2 // 收入
}

// 权限作用域枚举
export const ScopeEnum = {
    ALL: 'all',
    PC: 'pc'
}

// 权限编码枚举
export const SwitchCodeEnum = {
    W001: 'W001', // 天气预警提醒
    A001: 'A001' // 账单金额显示
}

// 发送短信验证码类型枚举
export const SendCodeTypeEnum = {
    REGIEST: 1, // 注册
    RESET: 2, // 重置密码
}

// 登录页选项类型枚举
export const LoginOptionTypeEnum = {
    REGIEST: 1, // 注册
    RESET: 2, // 重置密码
}