<template>
  <div>
    <div style="margin-top: 15px">
      <el-button type="danger" @click="showMenuView">装备配置项配置</el-button>
    </div>
    <div style="margin-top: 15px">
      <div>
        <el-select
            v-model="searchHottaEquipped.equippedMenuId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="装备配置项"
            clearable
            filterable
            @change="searchHottaEquipped.currentPage = 1; searchHottaEquipped.size = 10; initHottaEquipped()"
        >
          <el-option
              v-for="item in hottaEquippedMenus"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
            style="width: 200px;margin-right: 20px"
            placeholder="装备名称"
            v-model="searchHottaEquipped.name"
            clearable
            @keydown.enter.native="searchHottaEquipped.currentPage = 1; searchHottaEquipped.size = 10; initHottaEquipped()"
        ></el-input>
        <el-select
            v-model="searchHottaEquipped.type"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="等级类型"
            clearable
            filterable
            @change="searchHottaEquipped.currentPage = 1; searchHottaEquipped.size = 10; initHottaEquipped()"
        >
          <el-option
              v-for="item in hottaTypes"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" @click="resetHottaEquipped">
          重置
        </el-button>
        <el-button
            type="success"
            icon="el-icon-search"
            @click="searchHottaEquipped.currentPage = 1; searchHottaEquipped.size = 10; initHottaEquipped()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            icon="el-icon-plus"
            @click="showAddHottaEquippedView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('HottaEquipped') !== -1"
        >
          添加装备
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="hottaEquippedData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="装备名称"
            align="center"
            width="200"
            prop="name"
        ></el-table-column>
        <el-table-column
            label="装备配置项名称"
            align="center"
            width="150"
            prop="equippedMenuName"
        ></el-table-column>
        <el-table-column
            label="等级类型"
            align="center"
            width="200"
            prop="type"
        ></el-table-column>
        <el-table-column
            align="center"
            width="200"
            label="基础属性"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.basicAttributes" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.basicAttributes.replace(/\n/gm, '<br>')" slot="content">{{scope.row.basicAttributes}}</span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="200"
            label="强化属性"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.strengtheningAttributes" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.strengtheningAttributes.replace(/\n/gm, '<br>')" slot="content">{{scope.row.strengtheningAttributes}}</span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="200"
            label="随机属性"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.randomAttribute" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.randomAttribute.replace(/\n/gm, '<br>')" slot="content">{{scope.row.randomAttribute}}</span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            align="center"
            width="180"
            prop="createTime"
        ></el-table-column>
        <el-table-column
            label="更新时间"
            align="center"
            width="180"
            prop="updateTime"
        ></el-table-column>
        <el-table-column
            label="操作"
            width="180"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('HottaEquipped') !== -1" size="mini" type="primary" @click="showUpdateHottaEquippedView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('HottaEquipped') !== -1" size="mini" type="danger" @click="deleteHottaEquipped(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchHottaEquipped.currentPage"
          :page-size="searchHottaEquipped.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="800px">
      <el-form ref="hottaEquippedForm" :model="hottaEquipped" :rules="rules">
        <table>
          <tr>
            <el-form-item label="装备配置项" prop="equippedMenuId">
              <el-select
                  v-model="hottaEquipped.equippedMenuId"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="装备配置项"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in hottaEquippedMenus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="装备名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="hottaEquipped.name"
                  placeholder="请输入装备名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="等级类型" prop="type">
              <el-select
                  v-model="hottaEquipped.type"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="等级类型"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in hottaTypes"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="基础属性">
              <div id="richText1"></div>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="强化属性">
              <div id="richText2"></div>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="随机属性">
              <div id="richText3"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doHottaEquipped">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 装备配置项 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="menuTitle"
        @close="closeMenuDialog()"
        :visible.sync="menuDialogVisible"
        width="400px">
      <el-form ref="menuForm" :model="menuData" :rules="menuRules">
        <table>
          <tr>
            <el-form-item label="配置项编号">
              <el-select
                  v-model="menuData.id"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="配置项编号"
                  clearable
                  filterable
                  @change="initMenuInputData()"
              >
                <el-option
                    v-for="item in hottaEquippedMenus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="配置项名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="menuData.name"
                  placeholder="请输入配置项名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="配置项描述">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 200px"
                  v-model="menuData.remark"
                  placeholder="请输入配置项描述"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="menuDialogVisible = false">取 消</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('HottaEquipped') !== -1 && menuData.id !== null" type="danger" @click="deleteHottaEquippedMenu">删 除</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('HottaEquipped') !== -1" type="primary" @click="doHottaEquippedMenu">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import {
  addHottaEquipped, addHottaEquippedMenu, delHottaEquipped, delHottaEquippedMenu,
  hottaEquippedMenus, hottaEquippedPageData, upHottaEquipped, upHottaEquippedMenu
} from "@/api/hotta/equipped";
// wangeditor富文本
import E from "wangeditor"
let editor1;
let editor2;
let editor3;

export default {
  name: "HottaEquipped",
  data() {
    return {
      loading: false,
      searchHottaEquipped: {
        name: '',
        type: '',
        equippedMenuId: null,
        currentPage: 1,
        size: 10,
      },
      hottaEquipped: {
        id: null,
        name: '',
        type: '',
        equippedMenuId: null,
        basicAttributes: '',
        strengtheningAttributes: '',
        randomAttribute: ''
      },
      total: 0,
      hottaEquippedData: [],
      dialogVisible: false,
      title: '',
      rules: {
        equippedMenuId: [{required: true, message: "请选择装备配置", trigger: "blur"}],
        name: [{required: true, message: "请输入装备名称", trigger: "blur"}],
        type: [{required: true, message: "请选择等级类型", trigger: "blur"}],
      },
      hottaEquippedMenus: [],
      menuTitle: "",
      menuDialogVisible: false,
      menuData: {
        id: null,
        name: "",
        remark: ""
      },
      menuRules: {
        name: [{required: true, message: "请输入配置项名称", trigger: "blur"}],
      },
      roleButton: store.getters.currentRole,
      hottaTypes: store.getters.hottaTypes,
    };
  },
  computed: {

  },
  mounted() {
    this.initHottaEquipped();
    this.initHottaEquippedMenu();
  },
  created() {
    // 富文本 解决页面切换失效问题
    editor1 = '',
    editor2 = '',
    editor3 = ''
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initHottaEquipped() {
      this.loading = true;
      hottaEquippedPageData(this.searchHottaEquipped).then(resp => {
        this.loading = false;
        if (resp) {
          this.hottaEquippedData = resp.data;
          this.total = resp.total;
        }
      })
    },
    initHottaEquippedMenu() {
      hottaEquippedMenus().then(resp => {
        if (resp) {
          this.hottaEquippedMenus = resp;
        }
      })
    },
    currentChange(page) {
      this.searchHottaEquipped.currentPage = page;
      this.initHottaEquipped();
    },
    sizeChange(size) {
      this.searchHottaEquipped.size = size;
      this.initHottaEquipped();
    },
    deleteHottaEquipped(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]该装备, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delHottaEquipped(data.id).then(resp => {
              if (resp) {
                this.initHottaEquipped();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddHottaEquippedView() {
      this.hottaEquipped = {
        id: null,
        name: '',
        type: '',
        hottaEquippedMenuId: null,
        basicAttributes: '',
        strengtheningAttributes: '',
        randomAttribute: ''
      };
      this.dialogVisible = true;
      this.title = "添加装备";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor1) {
          editor1 = new E("#richText1");
          // 配置上传图片地址
          editor1.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor1.config.uploadFileName = "file";
          editor1.config.height = 200
          editor1.create();
        }
        if (!editor2) {
          editor2 = new E("#richText2");
          // 配置上传图片地址
          editor2.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor2.config.uploadFileName = "file";
          editor2.config.height = 200
          editor2.create();
        }
        if (!editor3) {
          editor3 = new E("#richText3");
          // 配置上传图片地址
          editor3.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor3.config.uploadFileName = "file";
          editor3.config.height = 200
          editor3.create();
        }
        // 清空内容
        editor1.txt.html('')
        editor2.txt.html('')
        editor3.txt.html('')
      })
    },
    showUpdateHottaEquippedView(data) {
      // 拷贝
      this.hottaEquipped = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
      this.title = "编辑装备";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor1) {
          editor1 = new E("#richText1");
          // 配置上传图片地址
          editor1.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor1.config.uploadFileName = "file";
          editor1.config.height = 200
          editor1.create();
        }
        if (!editor2) {
          editor2 = new E("#richText2");
          // 配置上传图片地址
          editor2.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor2.config.uploadFileName = "file";
          editor2.config.height = 200
          editor2.create();
        }
        if (!editor3) {
          editor3 = new E("#richText3");
          // 配置上传图片地址
          editor3.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor3.config.uploadFileName = "file";
          editor3.config.height = 200
          editor3.create();
        }
        // 内容赋值
        editor1.txt.html("");
        editor2.txt.html("");
        editor3.txt.html("");
        editor1.txt.html(this.hottaEquipped.basicAttributes);
        editor2.txt.html(this.hottaEquipped.strengtheningAttributes);
        editor3.txt.html(this.hottaEquipped.randomAttribute);
      })
    },
    doHottaEquipped() {
      if (this.hottaEquipped.id) {
        // 编辑
        this.$refs["hottaEquippedForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.hottaEquipped.basicAttributes = editor1.txt.html();
            this.hottaEquipped.strengtheningAttributes = editor2.txt.html();
            this.hottaEquipped.randomAttribute = editor3.txt.html();
            upHottaEquipped(this.hottaEquipped).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initHottaEquipped();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["hottaEquippedForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.hottaEquipped.basicAttributes = editor1.txt.html();
            this.hottaEquipped.strengtheningAttributes = editor2.txt.html();
            this.hottaEquipped.randomAttribute = editor3.txt.html();
            addHottaEquipped(this.hottaEquipped).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initHottaEquipped();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    closeDialog() {
      this.$refs["hottaEquippedForm"].resetFields();
    },
    resetHottaEquipped() {
      this.searchHottaEquipped = {
        name: '',
        type: '',
        hottaEquippedMenuId: null
      };
    },
    closeMenuDialog() {
      this.menuData.id = null;
      this.menuData.name = "";
      this.menuData.remark = "";
    },
    showMenuView() {
      this.initHottaEquippedMenu();
      this.menuTitle = '装备配置项配置';
      this.menuDialogVisible = true;
    },
    initMenuInputData() {
      let id = this.menuData.id;

      // 如果是清除就恢复最初数据
      if (id === '') {
        this.closeMenuDialog();
        return;
      }

      this.hottaEquippedMenus.forEach((item) => {
        if (String(item.id) === String(id)) {
          this.menuData.id = item.id;
          this.menuData.name = item.name;
          this.menuData.remark = item.remark;
        }
      })
    },
    deleteHottaEquippedMenu() {
      this.$confirm(
          "此操作将永久删除[" + this.menuData.name + "]的装备配置项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            delHottaEquippedMenu(this.menuData.id).then((resp) => {
              if (resp) {
                this.menuDialogVisible = false;
                this.initHottaEquippedMenu();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    doHottaEquippedMenu() {
      if (this.menuData.id) {
        // 编辑
        this.$refs["menuForm"].validate((valid) => {
          if (valid) {
            upHottaEquippedMenu(this.menuData).then(resp => {
              if (resp) {
                this.menuDialogVisible = false;
                this.initHottaEquippedMenu();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["menuForm"].validate((valid) => {
          if (valid) {
            addHottaEquippedMenu(this.menuData).then(resp => {
              if (resp) {
                this.menuDialogVisible = false;
                this.initHottaEquippedMenu();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
  }
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
