import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 版本列表
export function versionPageData(params = {}) {
  return postRequest("/sys/version/getPageData", params);
}

// 删除版本
export function delVersion(id) {
  return deleteRequest("/sys/version/" + id);
}

// 添加版本
export function addVersion(params = {}) {
  return postRequest("/sys/version", params);
}

// 更新版本
export function upVersion(params = {}) {
  return putRequest("/sys/version", params);
}