import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "@/utils/api.js";

// 获取所有的崩铁光锥信息
export function starRailLightAwls() {
    return getRequest("/star-rail/light-awl/getAll");
}

// 崩铁光锥列表
export function starRailLightAwlPageData(params = {}) {
    return postRequest("/star-rail/light-awl/getPageData", params);
}

// 删除崩铁光锥
export function delStarRailLightAwl(id) {
    return deleteRequest("/star-rail/light-awl/" + id);
}

// 添加崩铁光锥
export function addStarRailLightAwl(params = {}) {
    return postRequest("/star-rail/light-awl", params);
}

// 更新崩铁光锥
export function upStarRailLightAwl(params = {}) {
    return putRequest("/star-rail/light-awl", params);
}