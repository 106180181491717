const sys = {
    state: {
        sources: ['pc', 'app'],
    },
    mutations: {

    },
    actions: {}
}

export default sys