<template>
  <div>
    <el-collapse-transition>
      <div v-show="advanced">
        <div style="margin-top: 15px">
          <div>
            <el-select
                v-model="searchAccountRecord.roleId"
                size="mini"
                style="width: 200px;margin-right: 20px"
                placeholder="角色"
                clearable
                filterable
                @change="searchAccountRecord.currentPage = 1; searchAccountRecord.size = 10; initAccountRecord()"
            >
              <el-option
                  v-for="item in roles"
                  :key="item.id"
                  :label="item.realname"
                  :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
                v-model="searchAccountRecord.accountBookId"
                size="mini"
                style="width: 200px;margin-right: 20px"
                placeholder="账本"
                clearable
                filterable
                @change="searchAccountRecord.currentPage = 1; searchAccountRecord.size = 10; initAccountRecord()"
            >
              <el-option
                  v-for="item in accountBooks"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
                v-model="searchAccountRecord.accountCategory"
                size="mini"
                style="width: 200px;margin-right: 20px"
                placeholder="账单类别"
                clearable
                filterable
                @change="getTypesByCategory(searchAccountRecord.accountCategory)"
            >
              <el-option
                  v-for="item in accountCategories"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
              >
              </el-option>
            </el-select>
            <el-select
                v-model="searchAccountRecord.accountCategoryId"
                size="mini"
                style="width: 200px;margin-right: 20px"
                placeholder="账单类型"
                clearable
                filterable
                @change="searchAccountRecord.currentPage = 1; searchAccountRecord.size = 10; initAccountRecord()"
            >
              <el-option
                  v-for="item in accountTypes"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
            <el-input
                style="width: 200px;margin-right: 20px"
                size="mini"
                placeholder="账单名称"
                v-model="searchAccountRecord.name"
                clearable
                @keydown.enter.native="searchAccountRecord.currentPage = 1; searchAccountRecord.size = 10; initAccountRecord()"
            ></el-input>
          </div>
          <div style="margin-top: 10px">
            <el-date-picker
                style="margin-right: 20px"
                size="mini"
                :picker-options="pickerOptions"
                v-model="time"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="searchAccountRecord.currentPage = 1; searchAccountRecord.size = 10; initAccountRecord()"
            >
            </el-date-picker>
          </div>
          <div style="margin-top: 10px">
            <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetAccountRecord">
              重置
            </el-button>
            <el-button
                type="success"
                size="mini"
                icon="el-icon-search"
                @click="searchAccountRecord.currentPage = 1; searchAccountRecord.size = 10; initAccountRecord()"
            >
              搜索
            </el-button>
            <el-button icon="el-icon-delete"
                type="danger"
                size="mini"
                :disabled="multipleSelection.length === 0"
                @click="deleteRecords">
              删除选中的记录
            </el-button>
            <el-button
                type="success"
                size="mini"
                icon="el-icon-download"
                @click="exportData"
            >
              导出数据
            </el-button>
            <el-button
                type="primary"
                size="mini"
                icon="el-icon-plus"
                @click="showAddAccountRecordView"
            >
              添加账单
            </el-button>
            <el-button type="danger" size="mini" @click="showBookView">账本配置</el-button>
            <el-button type="warning" size="mini" @click="showTypeView">账单类型配置</el-button>
          </div>
          <div style="margin-top: 10px; display: flex; align-items: flex-start;">
            <el-upload
                ref="upload"
                style="margin-right: 20px"
                :show-file-list="false"
                :action="wxFilesUploadUrl"
                :on-success="filesUploadSuccess"
                :headers="headers"
            >
              <el-button type="primary" size="mini" icon="el-icon-upload2">微信数据导入</el-button>
            </el-upload>
            <el-upload
                ref="upload"
                style="margin-right: 20px"
                :show-file-list="false"
                :action="alipayFilesUploadUrl"
                :on-success="filesUploadSuccess"
                :headers="headers"
            >
              <el-button type="primary" size="mini" icon="el-icon-upload2">支付宝数据导入</el-button>
            </el-upload>
            <el-switch
                v-model="amtShow.authStatus"
                active-text="金额显示"
                inactive-text="金额不显示"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="AmtShowSwitchCode"
                style="padding-top: 6px; margin-right: 20px">
            </el-switch>
          </div>
        </div>
      </div>
    </el-collapse-transition>

    <div style="margin-top: 10px">
      <a @click="toggleAdvanced">
        {{ advanced ? '收起操作栏' : '展开操作栏' }}
        <!-- 我们通过vue给class动态赋值，
        添加一个三元判断，判断icon是向下还是向上，这个向下向的状态是基于
        advanced的状态 -->
        <i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
      </a>
    </div>

    <!-- 金额总览 -->
    <div style="margin-top: 10px" v-if="amtShow.authStatus">
      <span style="color: green">总计收入金额: {{this.incomeAmtSummary}}</span>
      <span style="color: red">&nbsp;&nbsp;总计支出金额: {{this.expenditureAmtSummary}}</span>
      <span style="color: blue">&nbsp;&nbsp;总计金额: {{this.amtSummary}}</span>
      <span style="color: orange">&nbsp;当前金额: {{this.amtNowSummary}}</span>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="accountRecordData"
          border
          stripe
          :row-class-name="tableRowClassName"
          :row-key="getRowKeys"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          @selection-change="handleSelectionChange"
      >
      <el-table-column align="center" width="55" type="selection"></el-table-column>
        <el-table-column
            label="账本名称"
            align="center"
            width="200"
            prop="bookName"
        ></el-table-column>
        <el-table-column
            label="账单类别"
            align="center"
            width="100"
        >
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.accountCategory === 1">支出</el-tag>
            <el-tag type="success" v-else-if="scope.row.accountCategory === 2">收入</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="账单类型"
            align="center"
            width="100"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.typeName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="账单名称"
            align="center"
            width="200"
            prop="name"
        ></el-table-column>
        <el-table-column
            label="账单金额"
            align="center"
            width="100"
        >
          <template slot-scope="scope">
            <el-tag type="danger" v-if="amtShow.authStatus && scope.row.accountCategory === 1">-{{scope.row.amt}}</el-tag>
            <el-tag type="success" v-else-if="amtShow.authStatus && scope.row.accountCategory === 2">+{{scope.row.amt}}</el-tag>
            <el-tag type="danger" v-else-if="!amtShow.authStatus && scope.row.accountCategory === 1">-x.xx</el-tag>
            <el-tag type="success" v-else-if="!amtShow.authStatus && scope.row.accountCategory === 2">+x.xx</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="更新时间"
            align="center"
            prop="updateTime"
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button size="mini" style="padding: 3px" type="primary" @click="showUpdateAccountRecordView(scope.row)">编辑</el-button>
            <el-button size="mini" style="padding: 3px" type="danger" @click="deleteAccountRecord(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchAccountRecord.currentPage"
          :page-size="searchAccountRecord.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <!-- 账单 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="400px">
      <el-form ref="accountRecordForm" :model="accountRecord" :rules="rules">
        <table>
          <tr>
            <el-form-item label="账本" prop="accountBookId">
              <el-select
                  v-model="accountRecord.accountBookId"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="账本"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in accountBooks"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单类别" prop="accountCategory">
              <el-select
                  v-model="accountRecord.accountCategory"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="账单类别"
                  clearable
                  filterable
                  @change="getTypesByCategoryDialog(accountRecord.accountCategory)"
              >
                <el-option
                    v-for="item in accountCategories"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单类型" prop="accountCategoryId">
              <el-select
                  v-model="accountRecord.accountCategoryId"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="账单类型"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in accountTypes"
                    :key="item.id"
                    :label="item.typeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="accountRecord.name"
                  placeholder="请输入账单名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单金额" prop="amt">
              <el-input-number
                  size="mini"
                  style="width: 200px"
                  v-model="accountRecord.amt"
                  placeholder="请输入账单金额"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单时间">
              <el-date-picker
                  style="width: 200px"
                  v-model="accountRecord.createTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doAccountRecord">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 账本 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="bookTitle"
        @close="closeBookDialog()"
        :visible.sync="bookDialogVisible"
        width="400px">
      <el-form ref="bookForm" :model="bookData" :rules="bookRules">
        <table>
          <tr>
            <el-form-item label="账本编号">
              <el-select
                  v-model="bookData.id"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="账本编号"
                  clearable
                  filterable
                  @change="initBookInputData()"
              >
                <el-option
                    v-for="item in accountBooks"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账本名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="bookData.name"
                  placeholder="请输入账本名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账本图标" prop="iconName">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="bookData.iconName"
                  placeholder="请输入账本图标"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账本排序">
              <el-input-number
                  :min="0"
                  :max="100"
                  size="mini"
                  style="width: 200px"
                  v-model="bookData.sort"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账本描述">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 200px"
                  v-model="bookData.remark"
                  placeholder="请输入账本描述"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="bookDialogVisible = false">取 消</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('AccountRecord') !== -1 && bookData.id !== null" type="danger" @click="deleteAccountBook">删 除</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('AccountRecord') !== -1" type="primary" @click="doAccountBook">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 账单类型 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="typeTitle"
        @close="closeTypeDialog()"
        :visible.sync="typeDialogVisible"
        width="400px">
      <el-form ref="typeForm" :model="typeData" :rules="typeRules">
        <table>
          <tr>
            <el-form-item label="账单类别" prop="category">
              <el-select
                  v-model="typeData.category"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="账单类别"
                  clearable
                  filterable
                  @change="getTypesByCategoryDialog(typeData.category)"
              >
                <el-option
                    v-for="item in accountCategories"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单类型">
              <el-select
                  v-model="typeData.id"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="账单类型"
                  clearable
                  filterable
                  @change="initTypeInputData()"
              >
                <el-option
                    v-for="item in accountTypes"
                    :key="item.id"
                    :label="item.typeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单类型" prop="typeName">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="typeData.typeName"
                  placeholder="请输入账单类型"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单图标" prop="iconName">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="typeData.iconName"
                  placeholder="请输入账单图标"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单排序">
              <el-input-number
                  :min="0"
                  :max="100"
                  size="mini"
                  style="width: 200px"
                  v-model="typeData.sort"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="账单类型描述">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 200px"
                  v-model="typeData.remark"
                  placeholder="请输入账单类型描述"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="bookDialogVisible = false">取 消</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('AccountRecord') !== -1 && typeData.id !== null" type="danger" @click="deleteAccountType">删 除</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('AccountRecord') !== -1" type="primary" @click="doAccountType">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
// wangeditor富文本
import store from "@/store";
import {
  accountAmtSummary,
  accountBooks, accountExport,
  accountPageData,
  accountTypesByCategory,
  addAccountBook,
  addAccountCategory,
  addAccountRecord,
  delAccountBook,
  delAccountCategory,
  delAccountRecord,
  upAccountBook,
  upAccountCategory,
  upAccountRecord,
  delAccountRecords
} from "@/api/person/accountRecord";
import {getAuthStatus, switchStatus} from "@/api/auth";
import {roles} from "@/api/sys/role";
import {ScopeEnum, SwitchCodeEnum} from "@/utils/constants";
let editor;

export default {
  name: "AccountRecord",
  data() {
    return {
      getRowKeys(row) {
        return row.id
      },
      pickerOptions: {
        shortcuts: [{
          text: '今日',
          onClick(picker) {
            var y = new Date().getFullYear();
            var m = new Date().getMonth();
            var d = new Date().getDate();
            const end = new Date(y + "-" + (m + 1) + "-" + d + " 23:59:59");
            const start = new Date(y + "-" + (m + 1) + "-" + d + " 00:00:00");
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '昨日',
          onClick(picker) {
            var today = new Date();
            var yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
            var y = yesterday.getFullYear();
            var m = yesterday.getMonth();
            var d = yesterday.getDate();
            const end = new Date(y + "-" + (m + 1) + "-" + d + " 23:59:59");
            const start = new Date(y + "-" + (m + 1) + "-" + d + " 00:00:00");
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            var today = new Date();
            var y = today.getFullYear();
            var m = today.getMonth();
            var d = today.getDate();
            var weekday = new Date(today.getTime() - 3600 * 1000 * 24 * 7);
            var wy = weekday.getFullYear();
            var wm = weekday.getMonth();
            var wd = weekday.getDate();
            const end = new Date(y + "-" + (m + 1) + "-" + d + " 23:59:59");
            const start = new Date(wy + "-" + (wm + 1) + "-" + wd + " 00:00:00");
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            var today = new Date();
            var y = today.getFullYear();
            var m = today.getMonth();
            var d = today.getDate();
            var monthday = new Date(today.getTime() - 3600 * 1000 * 24 * 30);
            var my = monthday.getFullYear();
            var mm = monthday.getMonth();
            var md = monthday.getDate();
            const end = new Date(y + "-" + (m + 1) + "-" + d + " 23:59:59");
            const start = new Date(my + "-" + (mm + 1) + "-" + md + " 00:00:00");
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '15号月度',
          onClick(picker) {
            var today = new Date();
            var y = today.getFullYear();
            var m = today.getMonth();
            var d = today.getDate();
            var start;
            var end;
            if (d >= 15) {
              start = new Date(y + "-" + (m + 1) + "-15 00:00:00");
              end = new Date(y + "-" + (m + 2) + "-15 23:59:59");
            } else {
              start = new Date(y + "-" + (m) + "-15 00:00:00");
              end = new Date(y + "-" + (m + 1) + "-15 23:59:59");
            }
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      loading: false,
      time: [],
      searchAccountRecord: {
        name: '',
        accountCategory: null,
        accountBookId: null,
        accountCategoryId: null,
        roleId: null,
        startTime: null,
        endTime: null,
        currentPage: 1,
        size: 10,
      },
      accountRecord: {
        id: null,
        name: '',
        amt: null,
        accountBookId: null,
        accountCategory: null,
        accountCategoryId: null,
        roleId: null,
        createTime: '',
      },
      total: 0,
      accountRecordData: [],
      dialogVisible: false,
      multipleSelection: [],
      incomeAmtSummary: null,
      expenditureAmtSummary: null,
      amtSummary: null,
      amtNowSummary: null,
      title: '',
      rules: {
        accountBookId: [{required: true, message: "请选择账本", trigger: "change"}],
        accountCategory: [{required: true, message: "请选择账单类别", trigger: "change"}],
        accountCategoryId: [{required: true, message: "请选择账单类型", trigger: "change"}],
        name: [{required: true, message: "请输入账单名称", trigger: "blur"}],
        amt: [{required: true, message: "请输入账单金额", trigger: "blur"}],
      },
      roles: [],
      accountBooks: [],
      accountTypes: [],
      bookTitle: "",
      bookDialogVisible: false,
      bookData: {
        id: null,
        sort: null,
        iconName: "",
        name: "",
        remark: ""
      },
      bookRules: {
        name: [{required: true, message: "请输入账本名称", trigger: "blur"}],
        iconName: [{required: true, message: "请输入账本类型图标", trigger: "blur"}],
      },
      typeTitle: "",
      typeDialogVisible: false,
      typeData: {
        id: null,
        category: null,
        sort: null,
        typeName: "",
        iconName: "",
        remark: ""
      },
      typeRules: {
        category: [{required: true, message: "请选择账单类别", trigger: "change"}],
        typeName: [{required: true, message: "请输入账单类型名称", trigger: "blur"}],
        iconName: [{required: true, message: "请输入账单类型图标", trigger: "blur"}],
      },
      wxFilesUploadUrl: window.server.filesUploadUrl + "/person/account-record/importWechatRecords",
      alipayFilesUploadUrl: window.server.filesUploadUrl + "/person/account-record/importAlipayRecords",
      headers: {
        'Authorization': window.sessionStorage.getItem('tokenStr')
      },
      advanced: true,
      roleButton: store.getters.currentRole,
      accountCategories: store.getters.accountCategories,
      amtShow: {
        authCode: SwitchCodeEnum.A001,
        authStatus: false,
        scope: ScopeEnum.PC
      }
    };
  },
  computed: {

  },
  async mounted() {
    this.getAmtShowAuthStatus();
    this.initAccountBooks();
    this.initRoles();

    // 接收参数
    let query = this.$route.query;
    if (query.startTime && query.endTime) {
      this.time = [query.startTime, query.endTime];
      this.searchAccountRecord.startTime = query.startTime;
      this.searchAccountRecord.endTime = query.endTime;
    }
    if (query.roleId) {
      this.searchAccountRecord.roleId = parseInt(query.roleId);
    }
    if (query.bookId) {
      this.searchAccountRecord.bookId = parseInt(query.bookId);
    }
    if (query.accountCategory && query.accountCategoryId) {
      this.searchAccountRecord.accountCategory = parseInt(query.accountCategory);
      this.getTypesByCategory(parseInt(query.accountCategory));
      this.searchAccountRecord.accountCategoryId = parseInt(query.accountCategoryId);
    }
    this.initAccountRecord();
  },
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    initAccountRecord() {
      // 组装开始结束时间
      if (this.time !== null && this.time.length === 2) {
        this.searchAccountRecord.startTime = this.time[0];
        this.searchAccountRecord.endTime = this.time[1];
      } else {
        this.searchAccountRecord.startTime = null;
        this.searchAccountRecord.endTime = null;
      }

      // 账单列表查询
      this.loading = true;
      accountPageData(this.searchAccountRecord).then(resp => {
        this.loading = false;
        if (resp) {
          this.accountRecordData = resp.data;
          this.total = resp.total;
        }
      })

      // 金额汇总
      accountAmtSummary(this.searchAccountRecord).then(resp => {
        if (resp) {
          this.incomeAmtSummary = resp.incomeAmtSummary;
          this.expenditureAmtSummary = resp.expenditureAmtSummary;
          this.amtSummary = resp.amtSummary;
          this.amtNowSummary = resp.amtNowSummary;
        }
      })
    },
    initAccountBooks() {
      accountBooks().then(resp => {
        if (resp) {
          this.accountBooks = resp;
        }
      })
    },
    initRoles() {
      roles().then(resp => {
        if (resp) {
          this.roles = resp;
        }
      })
    },
    currentChange(page) {
      this.searchAccountRecord.currentPage = page;
      this.initAccountRecord();
    },
    sizeChange(size) {
      this.searchAccountRecord.size = size;
      this.initAccountRecord();
    },
    deleteAccountRecord(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]该账单, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delAccountRecord(data.id).then(resp => {
              if (resp) {
                this.initAccountRecord();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddAccountRecordView() {
      this.accountRecord = {
        id: null,
        name: '',
        amt: null,
        accountBookId: null,
        accountCategory: null,
        accountCategoryId: null,
        roleId: null,
        createTime: '',
      };
      this.dialogVisible = true;
      this.title = "添加账单";
    },
    showUpdateAccountRecordView(data) {
      // 拷贝
      this.accountRecord = JSON.parse(JSON.stringify(data));
      // 反显正常显示 重新赋值
      let accountCategoryId = this.accountRecord.accountCategoryId;
      this.getTypesByCategoryDialog(this.accountRecord.accountCategory);
      this.accountRecord.accountCategoryId = accountCategoryId;
      this.dialogVisible = true;
      this.title = "编辑账单";
    },
    doAccountRecord() {
      if (this.accountRecord.id) {
        // 编辑
        this.$refs["accountRecordForm"].validate((valid) => {
          if (valid) {
            upAccountRecord(this.accountRecord).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                if (resp.data.isExceed) {
                  // 超出预期
                  this.$message.error(`当前月支出金额已超过预期，当前月总支出金额为 [${resp.data.expenditureAmt}] 元~`);
                }
                this.initAccountRecord();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["accountRecordForm"].validate((valid) => {
          if (valid) {
            addAccountRecord(this.accountRecord).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                if (resp.data.isExceed) {
                  // 超出预期
                  this.$message.error(`当前月支出金额已超过预期，当前月总支出金额为 [${resp.data.expenditureAmt}] 元~`);
                }
                this.initAccountRecord();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    closeDialog() {
      this.$refs["accountRecordForm"].resetFields();
    },
    resetAccountRecord() {
      this.searchAccountRecord = {
        name: '',
        accountCategory: null,
        accountBookId: null,
        accountCategoryId: null,
        roleId: null,
        startTime: null,
        endTime: null,
      };
      this.time = [];
    },
    closeBookDialog() {
      this.bookData.id = null;
      this.bookData.sort = null;
      this.bookData.name = "";
      this.bookData.iconName = "";
      this.bookData.remark = "";
    },
    showBookView() {
      this.initAccountBooks();
      this.bookTitle = '账本配置';
      this.bookDialogVisible = true;
    },
    initBookInputData() {
      let id = this.bookData.id;

      // 如果是清除就恢复最初数据
      if (id === '') {
        this.closeBookDialog();
        return;
      }

      this.accountBooks.forEach((item) => {
        if (String(item.id) === String(id)) {
          this.bookData.id = item.id;
          this.bookData.sort = item.sort;
          this.bookData.name = item.name;
          this.bookData.iconName = item.iconName;
          this.bookData.remark = item.remark;
        }
      })
    },
    deleteAccountBook() {
      this.$confirm(
          "此操作将永久删除[" + this.bookData.name + "]的账本, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            delAccountBook(this.bookData.id).then((resp) => {
              if (resp) {
                this.bookDialogVisible = false;
                this.initAccountBooks();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    doAccountBook() {
      if (this.bookData.id) {
        // 编辑
        this.$refs["bookForm"].validate((valid) => {
          if (valid) {
            upAccountBook(this.bookData).then(resp => {
              if (resp) {
                this.bookDialogVisible = false;
                this.initAccountBooks();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["bookForm"].validate((valid) => {
          if (valid) {
            addAccountBook(this.bookData).then(resp => {
              if (resp) {
                this.bookDialogVisible = false;
                this.initAccountBooks();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    getTypesByCategory(category) {
      this.accountTypes = [];
      this.searchAccountRecord.accountCategoryId = null;
      this.typeData.id = null;
      this.typeData.sort = null;
      this.typeData.typeName = "";
      this.typeData.iconName = "";
      if (category === null || category === undefined || category === '') {
        return;
      }
      accountTypesByCategory(category).then(resp => {
        this.accountTypes = resp;
      })

      this.searchAccountRecord.currentPage = 1;
      this.searchAccountRecord.size = 10;
      this.initAccountRecord();
    },
    getTypesByCategoryDialog(category) {
      this.accountTypes = [];
      this.accountRecord.accountCategoryId = null;
      this.typeData.id = null;
      this.typeData.sort = null;
      this.typeData.typeName = "";
      this.typeData.iconName = "";
      if (category === null || category === undefined || category === '') {
        return;
      }
      accountTypesByCategory(category).then(resp => {
        this.accountTypes = resp;
      })
    },
    closeTypeDialog() {
      this.typeData.id = null;
      this.typeData.sort = null;
      this.typeData.category = null;
      this.typeData.typeName = "";
      this.typeData.iconName = "";
      this.typeData.remark = "";
    },
    showTypeView() {
      this.typeTitle = '账单类型配置';
      this.typeDialogVisible = true;
    },
    initTypeInputData() {
      let id = this.typeData.id;

      // 如果是清除就恢复最初数据
      if (id === '') {
        this.closeTypeDialog();
        return;
      }

      this.accountTypes.forEach((item) => {
        if (String(item.id) === String(id)) {
          this.typeData.id = item.id;
          this.typeData.sort = item.sort;
          this.typeData.typeName = item.typeName;
          this.typeData.iconName = item.iconName;
          this.typeData.category = item.category;
          this.typeData.remark = item.remark;
        }
      })
    },
    deleteAccountType() {
      this.$confirm(
          "此操作将永久删除[" + this.typeData.typeName + "]的账单类型, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            delAccountCategory(this.typeData.id).then((resp) => {
              if (resp) {
                this.typeDialogVisible = false;
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    doAccountType() {
      if (this.typeData.id) {
        // 编辑
        this.$refs["typeForm"].validate((valid) => {
          if (valid) {
            upAccountCategory(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false;
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["typeForm"].validate((valid) => {
          if (valid) {
            addAccountCategory(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false;
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    exportData() {
      if (this.time !== null && this.time.length === 2) {
        this.searchAccountRecord.startTime = this.time[0];
        this.searchAccountRecord.endTime = this.time[1];
      } else {
        this.searchAccountRecord.startTime = null;
        this.searchAccountRecord.endTime = null;
      }
      accountExport(this.searchAccountRecord);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteRecords() {
      if (this.multipleSelection.length > 0) {
        let ids = []
        this.multipleSelection.forEach((val, index) => {
          ids.push(val.id);
        })

        let params = {
          ids: ids
        }

        this.$confirm(
            "此操作将永久删除选中的记录, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
        ).then(() => {
          delAccountRecords(params).then((resp) => {
            if (resp) {
              this.initAccountRecord();
            }
          });
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      }
    },
    filesUploadSuccess(res) {
      if (res.code === 200) {
        this.$message.success(res.message);
        this.initAccountRecord();
      } else {
        this.$message.error(res.message);
      }
    },
    getAmtShowAuthStatus() {
      getAuthStatus().then(resp => {
        if (resp) {
          resp.forEach(item => {
            if ((item.scope === ScopeEnum.PC || item.scope === ScopeEnum.ALL) && item.authCode === SwitchCodeEnum.A001) {
              this.amtShow.authStatus = item.authStatus;
            }
          });
        }
      });
    },
    AmtShowSwitchCode() {
      switchStatus(this.amtShow);
    }
  }
}

</script>

<style>
.el-checkbox {
  width: 14px;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label{
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>