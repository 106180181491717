import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 获取全部笔记类型
export function noteTypes(params = {}) {
  return postRequest("/work/note/getNoteTypeList", params);
}

// 笔记列表
export function notePageData(params = {}) {
  return postRequest("/work/note/getPageData", params);
}

// 删除笔记
export function delNote(id) {
  return deleteRequest("/work/note/" + id);
}

// 添加笔记
export function addNote(params = {}) {
  return postRequest("/work/note", params);
}

// 更新笔记
export function upNote(params = {}) {
  return putRequest("/work/note", params);
}

// 删除笔记类型
export function delNoteType(id) {
  return deleteRequest("/work/note/deleteNoteType/" + id);
}

// 更新笔记类型
export function upNoteType(params = {}) {
  return putRequest("/work/note/updateNoteType", params);
}

// 添加笔记类型
export function addNoteType(params = {}) {
  return postRequest("/work/note/addNoteType", params);
}
