import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "@/utils/api.js";

// 获取所有明日方舟定位信息
export function arknightsPositions() {
    return getRequest("/arknights/position/getAll");
}

// 获取全部父级定位信息
export function arknightsParentPositions() {
    return getRequest("/arknights/position/getAllParentPositions");
}

// 明日方舟定位记录列表
export function arknightsPositionPageData(params = {}) {
    return postRequest("/arknights/position/getPageData", params);
}

// 删除明日方舟定位
export function delArknightsPosition(id) {
    return deleteRequest("/arknights/position/" + id);
}

// 添加明日方舟定位
export function addArknightsPosition(params = {}) {
    return postRequest("/arknights/position", params);
}

// 更新明日方舟定位
export function upArknightsPosition(params = {}) {
    return putRequest("/arknights/position", params);
}