<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="材料名称"
            v-model="searchStarRailMaterials.name"
            clearable
            @keydown.enter.native="searchStarRailMaterials.currentPage = 1; searchStarRailMaterials.size = 10; initStarRailMaterials()"
        ></el-input>
        <el-select
            v-model="searchStarRailMaterials.type"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="材料类型"
            clearable
            filterable
            @change="searchStarRailMaterials.currentPage = 1; searchStarRailMaterials.size = 10; initStarRailMaterials()"
        >
          <el-option
              v-for="item in types"
              :key="item.code"
              :label="item.desc"
              :value="item.code"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchStarRailMaterials.name = ''; searchStarRailMaterials.type = null;">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchStarRailMaterials.currentPage = 1; searchStarRailMaterials.size = 10; initStarRailMaterials()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddStarRailMaterialsView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('StarRailMaterials') !== -1"
        >
          添加材料
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="starRailMaterialsData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="材料名称"
            align="center"
            prop="name"
        ></el-table-column>
        <el-table-column label="材料类型" align="center">
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.typeName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="消耗体力值"
            align="center"
            sortable
            width="150"
            prop="ps"
        ></el-table-column>
        <el-table-column
            label="创建时间"
            width="200"
            align="center"
            prop="createTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="更新时间"
            width="200"
            align="center"
            prop="updateTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('StarRailMaterials') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateStarRailMaterialsView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('StarRailMaterials') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteStarRailMaterials(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchStarRailMaterials.currentPage"
          :page-size="searchStarRailMaterials.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="400px">
      <el-form ref="starRailMaterialsForm" :model="starRailMaterials" :rules="rules">
        <table>
          <tr>
            <el-form-item label="材料名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="starRailMaterials.name"
                  placeholder="请输入材料名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="材料类型" prop="type">
              <el-select
                  v-model="starRailMaterials.type"
                  size="mini"
                  style="width: 200px"
                  placeholder="材料类型"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in types"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="消耗体力值">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="starRailMaterials.ps"
                  placeholder="请输入消耗体力值"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doStarRailMaterials">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import {addStarRailMaterials, starRailMaterialsPageData, starRailMaterialsTypes, delStarRailMaterials, upStarRailMaterials} from "@/api/star/materials";

export default {
  name: "StarRailMaterials",
  data() {
    return {
      loading: false,
      types: [],
      searchStarRailMaterials: {
        name: '',
        type: null,
        currentPage: 1,
        size: 10,
      },
      starRailMaterials: {
        id: null,
        name: '',
        type: '',
        ps: '',
      },
      total: 0,
      starRailMaterialsData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入材料名称", trigger: "blur"}],
        type: [{required: true, message: "请选择材料类型", trigger: "change"}],
      },
      roleButton: store.getters.currentRole,
    };
  },
  computed: {

  },
  mounted() {
    this.initStarRailMaterials();
    this.initTypes();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initTypes() {
      starRailMaterialsTypes().then(resp => {
        if (resp) {
          this.types = resp;
        }
      })
    },
    initStarRailMaterials() {
      this.loading = true;
      starRailMaterialsPageData(this.searchStarRailMaterials).then(resp => {
        this.loading = false;
        if (resp) {
          this.starRailMaterialsData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchStarRailMaterials.currentPage = page;
      this.initStarRailMaterials();
    },
    sizeChange(size) {
      this.searchStarRailMaterials.size = size;
      this.initStarRailMaterials();
    },
    deleteStarRailMaterials(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]该材料, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delStarRailMaterials(data.id).then(resp => {
              if (resp) {
                this.initStarRailMaterials();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddStarRailMaterialsView() {
      this.starRailMaterials = {
        id: null,
        name: '',
        type: '',
        ps: ''
      };
      this.dialogVisible = true;
      this.title = "添加材料";
    },
    showUpdateStarRailMaterialsView(data) {
      // 拷贝
      this.starRailMaterials = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
      this.title = "编辑材料";
    },
    doStarRailMaterials() {
      if (this.starRailMaterials.id) {
        // 编辑
        this.$refs["starRailMaterialsForm"].validate((valid) => {
          if (valid) {
            upStarRailMaterials(this.starRailMaterials).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initStarRailMaterials();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["starRailMaterialsForm"].validate((valid) => {
          if (valid) {
            addStarRailMaterials(this.starRailMaterials).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initStarRailMaterials();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    closeDialog() {
      this.$refs["starRailMaterialsForm"].resetFields();
    }
  },
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
