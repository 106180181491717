import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 获取小人国所有的等级类型
export function xrgLevelTypes() {
  return getRequest("/xrg/level/getLevelTypes");
}

// 小人国等级列表
export function xrgLevelPageData(params = {}) {
  return postRequest("/xrg/level/getPageData", params);
}
