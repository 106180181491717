<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="角色登录名"
            v-model="searchRole.username"
            clearable
            @keydown.enter.native="searchRole.currentPage = 1;searchRole.size = 8 ;initRoles()"
        ></el-input>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="角色名称"
            v-model="searchRole.realname"
            clearable
            @keydown.enter.native="searchRole.currentPage = 1;searchRole.size = 8 ;initRoles()"
        ></el-input>
        <el-select
            v-model="searchRole.sex"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色性别"
            filterable
            clearable
            @change="searchRole.currentPage = 1;searchRole.size = 8 ;initRoles()"
        >
          <el-option
              v-for="item in sexs"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchRole.levelId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色等级"
            filterable
            clearable
            @change="searchRole.currentPage = 1;searchRole.size = 8 ;initRoles()"
        >
          <el-option
              v-for="item in levels"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-select
            v-model="searchRole.countryId"
            size="mini"
            @change="getAreasByCountry(searchRole.countryId)"
            style="width: 200px;margin-right: 20px"
            clearable
            filterable
            placeholder="所属国家"
        >
          <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchRole.areaId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="所属地区"
            clearable
            filterable
            @change="searchRole.currentPage = 1;searchRole.size = 8 ;initRoles()"
        >
          <el-option
              v-for="item in areas"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetData">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchRole.currentPage = 1;searchRole.size = 8 ;initRoles()"
        >
          搜索
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="roles"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            prop="username"
            label="角色登录名"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="realname"
            label="角色名称"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="sex"
            label="角色性别"
            width="100"
        >
        </el-table-column>
        <el-table-column
            prop="age"
            label="角色年龄"
            width="100"
        >
        </el-table-column>
        <el-table-column
            prop="level.name"
            label="角色等级"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="country.name"
            label="所属国家"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="area.name"
            label="所属地区"
            width="150"
        >
        </el-table-column>
        <el-table-column
            label="角色头像"
            width="100"
        >
          <template #default="scope">
            <el-image
                style="width: 50px; height: 50px"
                :src="initUserFace(scope.row.userFace)"
                :preview-src-list="[initUserFace(scope.row.userFace)]"
            >
              <template #error>
                <div
                    class="image-slot"
                    style="text-align: center; line-height: 49px; border: 1px solid #ccc;"
                >
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="250"
            label="角色来源"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.source && scope.row.source.length <= 25" v-html="scope.row.source.replace(/\n/gm, '<br>')" slot="content">{{scope.row.source}}</span>
            <el-tooltip v-if="scope.row.source && scope.row.source.length > 25" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.source.replace(/\n/gm, '<br>')" slot="content">{{scope.row.source}}</span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-if="!scope.row.source">-</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="250"
            label="角色描述"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.remark && scope.row.remark.length <= 25" v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
            <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 25" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-if="!scope.row.remark">-</span>
          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex; justify-content: flex-end; margin-top: 5px">
        <el-pagination
            background
            layout="sizes, prev, pager, next, jumper, ->, total, slot"
            :page-sizes="[5, 8, 20, 40, 50, 100]"
            :current-page="searchRole.currentPage"
            :page-size="searchRole.size"
            :total="total"
            @current-change="currentChange"
            @size-change="sizeChange"
        >
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import store from "@/store";
import {xrgCountries} from "@/api/xrg/area";
import {xrgAreas, xrgLevels, xrgRolePageData, xrgAreasByCountry} from "@/api/xrg/role";

export default {
  name: "Role",
  data() {
    return {
      loading: false,
      roles: [],
      total: 0,
      searchRole: {
        username: "",
        realname: "",
        sex: "",
        levelId: null,
        countryId: null,
        areaId: null,
        currentPage: 1,
        size: 8
      },
      areas: [],
      levels: [],
      buttonLevels: [],
      countries: [],
      filesUploadUrl: window.server.filesUploadUrl + "/files/upload",
      roleButton: store.getters.currentRole,
      sexs: store.getters.sexs,
    }
  },
  computed: {

  },
  async mounted() {
    this.initLevels();
    this.initCountries();

    // 接收参数
    let query = this.$route.query;
    if (query.countryId) {
      this.searchRole.countryId = parseInt(query.countryId);
    }
    this.initRoles();
  },
  methods: {
    initRoles() {
      this.loading = true;
      xrgRolePageData(this.searchRole).then(resp => {
        this.loading = false;
        if (resp) {
          this.total = resp.total;
          this.roles = resp.data;
        }
      })
    },
    currentChange(page) {
      this.searchRole.currentPage = page;
      this.initRoles();
    },
    sizeChange(size) {
      this.searchRole.size = size;
      this.initRoles();
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initAreas() {
      xrgAreas().then(resp => {
        if (resp) {
          this.areas = resp;
        }
      })
    },
    initLevels() {
      xrgLevels().then(resp => {
        if (resp) {
          this.levels = resp;
        }
      })
    },
    initCountries() {
      xrgCountries().then(resp => {
        if (resp) {
          this.countries = resp;
        }
      })
    },
    getAreasByCountry(countryId) {
      this.searchRole.areaId = null;
      this.areas = [];
      if (countryId === null || countryId === undefined || countryId === '') {
        return;
      }
      xrgAreasByCountry(countryId).then(resp => {
        if (resp) {
          this.areas = resp;
        }
      })
      this.searchRole.currentPage = 1;
      this.searchRole.size = 8;
      this.initRoles();
    },
    resetData() {
      this.searchRole.username = "";
      this.searchRole.realname = "";
      this.searchRole.sex = "";
      this.searchRole.levelId = null;
      this.searchRole.countryId = null;
      this.searchRole.areaId = null;
      this.areas = [];
    },
    filesUploadSuccess(res) {
      this.role.userFace = res.data;
    },
    initUserFace(image) {
      return window.server.apiBase + "/files?filePath=" + image + "&name=";
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
