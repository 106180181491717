<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="游戏名称"
            v-model="searchGame.name"
            clearable
            @keydown.enter.native="searchGame.currentPage = 1; searchGame.size = 10; initGame()"
        ></el-input>
        <el-select
            v-model="searchGame.type"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="游戏类型"
            clearable
            filterable
            @change="searchGame.currentPage = 1; searchGame.size = 10; initGame()"
        >
          <el-option
              v-for="item in gameTypes"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini"
                   @click="searchGame.name = ''; searchGame.type = '';">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchGame.currentPage = 1; searchGame.size = 10; initGame()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddGameView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Game') !== -1"
        >
          添加游戏
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="gameData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="游戏名称"
            align="center"
            prop="name"
        ></el-table-column>
        <el-table-column
            label="游戏类型"
            align="center"
            prop="type"
        ></el-table-column>
        <el-table-column
            label="创建时间"
            width="200"
            align="center"
            prop="createTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="更新时间"
            width="200"
            align="center"
            prop="updateTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button
                v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Game') !== -1"
                size="mini" style="padding: 3px" type="primary" @click="showUpdateGameView(scope.row)">编辑
            </el-button>
            <el-button
                v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Game') !== -1"
                size="mini" style="padding: 3px" type="danger" @click="deleteGame(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchGame.currentPage"
          :page-size="searchGame.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="400px">
      <el-form ref="gameForm" :model="game" :rules="rules">
        <table>
          <tr>
            <el-form-item label="游戏名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="game.name"
                  placeholder="请输入游戏名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="游戏类型" prop="type">
              <el-select
                  v-model="game.type"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="游戏类型"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in gameTypes"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doGame">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
import store from "@/store";
import {addGame, delGame, gamePageData, upGame} from "@/api/record/game";

export default {
  name: "Game",
  data() {
    return {
      gameData: [],
      loading: false,
      searchGame: {
        name: '',
        type: '',
        currentPage: 1,
        size: 10,
      },
      game: {
        id: null,
        name: '',
        type: ''
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入游戏名称", trigger: "blur"}],
        type: [{required: true, message: "请选择游戏类型", trigger: "change"}],
      },
      gameTypes: store.getters.gameTypes,
      roleButton: store.getters.currentRole,
    }
  },
  computed: {},
  mounted() {
    this.initGame();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initGame() {
      this.loading = true;
      gamePageData(this.searchGame).then(resp => {
        this.loading = false;
        if (resp) {
          this.gameData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchGame.currentPage = page;
      this.initGame();
    },
    sizeChange(size) {
      this.searchGame.size = size;
      this.initGame();
    },
    deleteGame(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]游戏, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delGame(data.id).then(resp => {
              if (resp) {
                this.initGame();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddGameView() {
      this.game = {
        id: null,
        name: '',
        type: ''
      };
      this.dialogVisible = true;
      this.title = "添加游戏";
    },
    showUpdateGameView(data) {
      // 拷贝
      this.game = Object.assign({}, data);
      this.dialogVisible = true;
      this.title = "编辑游戏";
    },
    closeDialog() {
      this.$refs["gameForm"].resetFields();
    },
    doGame() {
      if (this.game.id) {
        // 编辑
        this.$refs["gameForm"].validate((valid) => {
          if (valid) {
            upGame(this.game).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initGame();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["gameForm"].validate((valid) => {
          if (valid) {
            addGame(this.game).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initGame();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>