import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 获取所有歌单类型
export function musicMenus() {
  return getRequest("/record/music/getMusicMenuList");
}

// 根据歌单获取所有歌曲名称歌手
export function musicNamesByMenuId(id) {
  return postRequest("/record/music/getMusicNamesByMenuId/" + id);
}

// 音乐列表
export function musicPageData(params = {}) {
  return postRequest("/record/music/getPageData", params);
}

// 删除音乐
export function delMusic(id) {
  return deleteRequest("/record/music/" + id);
}

// 添加音乐
export function addMusic(params = {}) {
  return postRequest("/record/music", params);
}

// 更新音乐
export function upMusic(params = {}) {
  return putRequest("/record/music", params);
}

// 删除歌单类型
export function delMusicMenu(id) {
  return deleteRequest("/record/music/deleteMusicMenu/" + id);
}

// 更新歌单类型
export function upMusicMenu(params = {}) {
  return putRequest("/record/music/updateMusicMenu", params);
}

// 新增歌单类型
export function addMusicMenu(params = {}) {
  return postRequest("/record/music/addMusicMenu", params);
}