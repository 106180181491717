<template>
  <div>
    <div style="margin-top: 15px">
      <div>
        <el-select
            v-model="searchMusic.musicMenuId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="音乐歌单"
            clearable
            filterable
            @change="searchMusic.currentPage = 1; searchMusic.size = 10; searchMusic.menuSwitch = null; initMusic()"
        >
          <el-option
              v-for="item in musicMenus"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="音乐名称"
            v-model="searchMusic.name"
            clearable
            @keydown.enter.native="searchMusic.currentPage = 1; searchMusic.size = 10; initMusic()"
        ></el-input>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="音乐歌手"
            v-model="searchMusic.singer"
            clearable
            @keydown.enter.native="searchMusic.currentPage = 1; searchMusic.size = 10; initMusic()"
        ></el-input>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="音乐类型"
            v-model="searchMusic.type"
            clearable
            @keydown.enter.native="searchMusic.currentPage = 1; searchMusic.size = 10; initMusic()"
        ></el-input>
      </div>
      <div style="margin-top: 10px">
        <el-select
            v-model="searchMusic.isStudy"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="是否学习"
            clearable
            filterable
            @change="searchMusic.currentPage = 1; searchMusic.size = 10; initMusic()"
        >
          <el-option value="true" label="已学习">已学习</el-option>
          <el-option value="false" label="未学习">未学习</el-option>
        </el-select>
        <el-select
            v-if="!searchMusic.musicMenuId"
            v-model="searchMusic.menuSwitch"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="加入歌单"
            clearable
            filterable
            @change="searchMusic.currentPage = 1; searchMusic.size = 10; initMusic()"
        >
          <el-option value="true" label="已加入">已加入</el-option>
          <el-option value="false" label="未加入">未加入</el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetMusic">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchMusic.currentPage = 1; searchMusic.size = 10; initMusic()"
        >
          搜索
        </el-button>
        <el-button
            type="warning"
            size="mini"
            icon="el-icon-search"
            @click="showMenuMusicView(searchMusic.musicMenuId)"
        >
          查看歌单歌曲
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddMusicView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Music') !== -1"
        >
          添加音乐
        </el-button>
        <el-button type="danger" size="mini" @click="showMenuView">歌单配置</el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="musicData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="音乐名称"
            align="center"
            width="200"
            prop="name"
        ></el-table-column>
        <el-table-column
            label="音乐歌手"
            align="center"
            width="200"
            prop="singer"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.singer && commonApi.toText(scope.row.singer).length > 15" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.singer.replace(/\n/gm, '<br>')" slot="content">{{scope.row.singer}}</span>
              <span v-if="scope.row.singer" v-html="$options.filters.ellipsis(scope.row.singer)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.singer" v-html="commonApi.toText(scope.row.singer)"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="音乐歌单"
            align="center"
            width="150"
            prop="musicMenuName"
        ></el-table-column>
        <el-table-column
            label="音乐类型"
            align="center"
            width="200"
            prop="type"
        ></el-table-column>
        <el-table-column
            label="音乐地址"
            width="200"
            align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.url === null || scope.row.url === '' || scope.row.url === undefined">-</span>
            <a target="_blank" v-else :href="scope.row.url">{{ scope.row.name }}</a>
          </template>
        </el-table-column>
        <el-table-column
            label="是否学习"
            width="100"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.isStudy">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="学习完成时间"
            align="center"
            width="180"
            prop="studyTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="音乐学习地址"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.studyUrl === null || scope.row.studyUrl === '' || scope.row.studyUrl === undefined">-</span>
            <a target="_blank" v-else :href="scope.row.studyUrl">{{ scope.row.name }}</a>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="250"
            label="音乐描述"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.remark && commonApi.toText(scope.row.remark).length > 15" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsis(scope.row.remark)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="commonApi.toText(scope.row.remark)" slot="content"></span>
            <span v-if="!scope.row.remark">-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            align="center"
            width="180"
            prop="createTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="更新时间"
            align="center"
            width="180"
            prop="updateTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Music') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateMusicView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Music') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteMusic(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchMusic.currentPage"
          :page-size="searchMusic.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="800px">
      <el-form ref="musicForm" :model="music" :rules="rules">
        <table>
          <tr>
            <el-form-item label="音乐歌单">
              <el-select
                  v-model="music.musicMenuId"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="音乐歌单"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in musicMenus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="音乐名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="music.name"
                  placeholder="请输入音乐名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="音乐歌手" prop="singer">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="music.singer"
                  placeholder="请输入音乐歌手"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="音乐类型">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="music.type"
                  placeholder="请输入音乐类型"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="音乐地址" prop="url">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="music.url"
                  placeholder="请输入音乐地址"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="是否学习">
              <el-switch
                  v-model="music.isStudy"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  style="padding-top: 6px">
              </el-switch>
            </el-form-item>
          </tr>
          <tr v-if="music.isStudy">
            <el-form-item label="音乐学习地址">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="music.studyUrl"
                  placeholder="请输入音乐学习地址"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="音乐描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doMusic">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 歌单 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="menuTitle"
        @close="closeMenuDialog()"
        :visible.sync="menuDialogVisible"
        width="400px">
      <el-form ref="menuForm" :model="menuData" :rules="menuRules">
        <table>
          <tr>
            <el-form-item label="歌单编号">
              <el-select
                  v-model="menuData.id"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="歌单编号"
                  clearable
                  filterable
                  @change="initMenuInputData()"
              >
                <el-option
                    v-for="item in musicMenus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="歌单名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="menuData.name"
                  placeholder="请输入歌单名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="歌单描述">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 200px"
                  v-model="menuData.remark"
                  placeholder="请输入歌单描述"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="menuDialogVisible = false">取 消</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Music') !== -1 && menuData.id !== null" type="danger" @click="deleteMusicMenu">删 除</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Music') !== -1" type="primary" @click="doMusicMenu">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 歌单歌词 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="menuMusicTitle"
        @close="closeMenuMusicDialog()"
        :visible.sync="menuMusicDialogVisible"
        width="800px">
      <el-card>
        <el-row :gutter="12">
          <el-col :style="{ marginTop: (index === 0 || index === 1 || index === 2) ? '0px' : '20px' }" :span="8" v-for="(item, index) in musicNames">
            『{{ item }}』
          </el-col>
        </el-row>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from "wangeditor"
import store from "@/store";
import {
  addMusic, addMusicMenu, delMusic, delMusicMenu, musicMenus,
  musicNamesByMenuId, musicPageData, upMusic, upMusicMenu
} from "@/api/record/music";
let editor;

export default {
  name: "Music",
  data() {
    return {
      loading: false,
      searchMusic: {
        name: '',
        singer: '',
        type: '',
        musicMenuId: null,
        isStudy: null,
        menuSwitch: null,
        currentPage: 1,
        size: 10,
      },
      music: {
        id: null,
        name: '',
        singer: '',
        musicMenuId: null,
        type: '',
        url: '',
        isStudy: false,
        studyUrl: '',
      },
      total: 0,
      musicData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入音乐名称", trigger: "blur"}],
        singer: [{required: true, message: "请输入音乐歌手", trigger: "blur"}],
        url: [{required: true, message: "请输入音乐地址", trigger: "blur"}],
      },
      musicMenus: [],
      musicNames: [],
      menuTitle: "",
      menuDialogVisible: false,
      menuData: {
        id: null,
        name: "",
        remark: ""
      },
      menuRules: {
        name: [{required: true, message: "请输入歌单名称", trigger: "blur"}],
      },
      menuMusicTitle: "",
      menuMusicDialogVisible: false,
      roleButton: store.getters.currentRole,
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      let input = value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
      if (input.length > 15) {
        return input.slice(0, 15) + '...';
      } else {
        return input;
      }
    }
  },
  computed: {

  },
  mounted() {
    this.initMusic();
    this.initMusicMenu();
  },
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initMusic() {
      this.loading = true;
      musicPageData(this.searchMusic).then(resp => {
        this.loading = false;
        if (resp) {
          this.musicData = resp.data;
          this.total = resp.total;
        }
      })
    },
    initMusicMenu() {
      musicMenus().then(resp => {
        if (resp) {
          this.musicMenus = resp;
        }
      })
    },
    getMusicNamesByMenuId(menuId) {
      musicNamesByMenuId(menuId).then(resp => {
        if (resp) {
          this.musicNames = resp;
        }
      })
    },
    currentChange(page) {
      this.searchMusic.currentPage = page;
      this.initMusic();
    },
    sizeChange(size) {
      this.searchMusic.size = size;
      this.initMusic();
    },
    deleteMusic(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]该音乐, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delMusic(data.id).then(resp => {
              if (resp) {
                this.initMusic();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddMusicView() {
      this.music = {
        id: null,
        name: '',
        singer: '',
        musicMenuId: null,
        type: '',
        url: '',
        isStudy: false,
        studyUrl: ''
      };
      this.dialogVisible = true;
      this.title = "添加音乐";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateMusicView(data) {
      // 拷贝
      this.music = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
      this.title = "编辑音乐";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 内容赋值
        editor.txt.html("");
        editor.txt.html(this.music.remark);
      })
    },
    doMusic() {
      if (this.music.id) {
        // 编辑
        this.$refs["musicForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.music.remark = editor.txt.html();
            upMusic(this.music).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initMusic();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["musicForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.music.remark = editor.txt.html();
            addMusic(this.music).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initMusic();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    closeDialog() {
      this.$refs["musicForm"].resetFields();
    },
    resetMusic() {
      this.searchMusic = {
        name: '',
        singer: '',
        type: '',
        musicMenuId: null,
        isStudy: null,
        menuSwitch: null,
      };
    },
    closeMenuDialog() {
      this.menuData.id = null;
      this.menuData.name = "";
      this.menuData.remark = "";
    },
    showMenuView() {
      this.initMusicMenu();
      this.menuTitle = '歌单配置';
      this.menuDialogVisible = true;
    },
    initMenuInputData() {
      let id = this.menuData.id;

      // 如果是清除就恢复最初数据
      if (id === '') {
        this.closeMenuDialog();
        return;
      }

      this.musicMenus.forEach((item) => {
        if (String(item.id) === String(id)) {
          this.menuData.id = item.id;
          this.menuData.name = item.name;
          this.menuData.remark = item.remark;
        }
      })
    },
    deleteMusicMenu() {
      this.$confirm(
          "此操作将永久删除[" + this.menuData.name + "]的歌单, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            delMusicMenu(this.menuData.id).then((resp) => {
              if (resp) {
                this.menuDialogVisible = false;
                this.initMusicMenu();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    doMusicMenu() {
      if (this.menuData.id) {
        // 编辑
        this.$refs["menuForm"].validate((valid) => {
          if (valid) {
            upMusicMenu(this.menuData).then(resp => {
              if (resp) {
                this.menuDialogVisible = false;
                this.initMusicMenu();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["menuForm"].validate((valid) => {
          if (valid) {
            addMusicMenu(this.menuData).then(resp => {
              if (resp) {
                this.menuDialogVisible = false;
                this.initMusicMenu();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    showMenuMusicView(menuId) {
      if (!menuId) {
        this.$message.error("请先选择音乐歌单~");
        return;
      }
      let menuName = '';
      this.musicMenus.forEach((item) => {
        if (item.id === menuId) {
          menuName = item.name;
        }
      });
      this.getMusicNamesByMenuId(menuId);
      this.menuMusicTitle = '歌单所有歌曲名称 歌单 =>『' + menuName + '』';
      this.menuMusicDialogVisible = true;
    },
    closeMenuMusicDialog() {
      this.musicNames = [];
    },
  }
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
