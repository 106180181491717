import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 新增消息通知
export function addMsgNotification(params = {}) {
  return postRequest("/person/message-notification", params);
}

// 消息通知列表
export function prMsgNotificationPageData(params = {}) {
  return postRequest("/person/message-notification/getPageData", params);
}

// 消息通知详情信息
export function prMsgNotificationDetail(id) {
  return postRequest("/person/message-notification/getDetail/" + id);
}

// 删除消息通知
export function delPrMsgNotification(id) {
  return deleteRequest("/person/message-notification/" + id);
}