import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 获取所有首页模块类型
export function homeModuleTypes() {
  return getRequest("/sys/home-module/getTypes");
}

// 首页模块信息列表
export function homeModulePageData(params = {}) {
  return postRequest("/sys/home-module/getPageData", params);
}

// 删除模块信息
export function delHomeModule(id) {
  return deleteRequest("/sys/home-module/" + id);
}

// 添加模块信息
export function addHomeModule(params = {}) {
  return postRequest("/sys/home-module", params);
}

// 更新模块信息
export function upHomeModule(params = {}) {
  return putRequest("/sys/home-module", params);
}