import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 笔记列表
export function prNotePageData(params = {}) {
  return postRequest("/person/note/getPageData", params);
}

// 获取所有笔记类型
export function prNoteTypes(params = {}) {
  return postRequest("/person/note/getNoteTypeList", params);
}

// 删除笔记
export function delPrNote(id) {
  return deleteRequest("/person/note/" + id);
}

// 添加笔记
export function addPrNote(params = {}) {
  return postRequest("/person/note", params);
}

// 更新笔记
export function upPrNote(params = {}) {
  return putRequest("/person/note", params);
}

// 删除笔记类型
export function delPrNoteType(id) {
  return deleteRequest("/person/note/deleteNoteType/" + id);
}

// 更新笔记类型
export function upPrNoteType(params = {}) {
  return putRequest("/person/note/updateNoteType", params);
}

// 新增笔记类型
export function addPrNoteType(params = {}) {
  return postRequest("/person/note/addNoteType", params);
}
