<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="角色名称"
            v-model="searchStarRailRole.name"
            clearable
            @keydown.enter.native="searchStarRailRole.currentPage = 1; searchStarRailRole.size = 10; initStarRailRole()"
        ></el-input>
        <el-select
            v-model="searchStarRailRole.star"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色星级"
            clearable
            filterable
            @change="searchStarRailRole.currentPage = 1; searchStarRailRole.size = 10; initStarRailRole()"
        >
          <el-option
              v-for="item in stars"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchStarRailRole.attributes"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色属性"
            clearable
            filterable
            @change="searchStarRailRole.currentPage = 1; searchStarRailRole.size = 10; initStarRailRole()"
        >
          <el-option
              v-for="item in starRailAttributes"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchStarRailRole.destinyId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色命途"
            clearable
            filterable
            @change="searchStarRailRole.currentPage = 1; searchStarRailRole.size = 10; initStarRailRole()"
        >
          <el-option
              v-for="item in destinies"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-select
            v-model="searchStarRailRole.lightAwlId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色光锥"
            clearable
            filterable
            @change="searchStarRailRole.currentPage = 1; searchStarRailRole.size = 10; initStarRailRole()"
        >
          <el-option
              v-for="item in lightAwls"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetStarRailRole">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchStarRailRole.currentPage = 1; searchStarRailRole.size = 10; initStarRailRole()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddStarRailRoleView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('StarRailRole') !== -1"
        >
          添加角色
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="starRailRoleData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="角色名称"
            align="center"
            width="100"
            prop="name"
        ></el-table-column>
        <el-table-column
            label="角色星级"
            align="center"
            width="100"
            sortable
            prop="star"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.star}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色属性"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="success">{{scope.row.attributes}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色命途"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="danger">{{scope.row.destiny.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色光锥"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.lightAwl.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色遗器外"
            width="300"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.relicOut" type="info">{{scope.row.relicOut.name}}</el-tag>
            <span v-if="scope.row.relicOut && scope.row.relicOutTwo">
              + <el-tag type="info">{{scope.row.relicOutTwo.name}}</el-tag>
            </span>
            <span v-if="!scope.row.relicOut">-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="角色遗器内"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.relicIn" type="info">{{scope.row.relicIn.name}}</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="250"
            label="角色描述"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.remark && commonApi.toText(scope.row.remark).length > 15" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsis(scope.row.remark)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="commonApi.toText(scope.row.remark)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('StarRailRole') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateStarRailRoleView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('StarRailRole') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteStarRailRole(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchStarRailRole.currentPage"
          :page-size="searchStarRailRole.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="800px">
      <el-form ref="starRailRoleForm" :model="starRailRole" :rules="rules">
        <table>
          <tr>
            <el-form-item label="角色名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="starRailRole.name"
                  placeholder="请输入角色名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色星级" prop="star">
              <el-select
                  v-model="starRailRole.star"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色星级"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in stars"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色属性" prop="attributes">
              <el-select
                  v-model="starRailRole.attributes"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色属性"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in starRailAttributes"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色命途" prop="destinyId">
              <el-select
                  v-model="starRailRole.destinyId"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色命途"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in destinies"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色光锥" prop="lightAwlId">
              <el-select
                  v-model="starRailRole.lightAwlId"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色光锥"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in lightAwls"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色遗器外">
              <el-select
                  v-model="starRailRole.relicOutId"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色遗器外"
                  clearable
                  filterable
                  @change="changeUpTwo"
              >
                <el-option
                    v-for="item in outRelics"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>

              <el-select
                  v-if="starRailRole.relicOutId"
                  v-model="starRailRole.relicOutTwoId"
                  size="mini"
                  style="width: 200px; margin-left: 15px"
                  placeholder="2+2件套"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in outTwoRelics"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色遗器内">
              <el-select
                  v-model="starRailRole.relicInId"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色遗器内"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in inRelics"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doStarRailRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
// wangeditor富文本
import E from "wangeditor"
import {starRailDestinies} from "@/api/star/destiny";
import {starRailLightAwls} from "@/api/star/lightAwl";
import {starRailRelics} from "@/api/star/relic";
import {addStarRailRole, starRailRolePageData, delStarRailRole, upStarRailRole} from "@/api/star/role";
let editor;

export default {
  name: "StarRailRole",
  data() {
    return {
      loading: false,
      destinies: [],
      lightAwls: [],
      inRelics: [],
      outRelics: [],
      outTwoRelics: [],
      searchStarRailRole: {
        name: '',
        star: null,
        attributes: '',
        destinyId: null,
        lightAwlId: null,
        currentPage: 1,
        size: 10,
      },
      starRailRole: {
        id: null,
        name: '',
        star: null,
        attributes: '',
        destinyId: null,
        lightAwlId: null,
        relicOutId: null,
        relicOutTwoId: null,
        relicInId: null,
        remark: ''
      },
      total: 0,
      starRailRoleData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入角色名称", trigger: "blur"}],
        star: [{required: true, message: "请选择角色星级", trigger: "change"}],
        attributes: [{required: true, message: "请选择角色属性", trigger: "change"}],
        destinyId: [{required: true, message: "请选择角色命途", trigger: "change"}],
        lightAwlId: [{required: true, message: "请选择角色光锥", trigger: "change"}],
      },
      roleButton: store.getters.currentRole,
      stars: store.getters.starRailStars,
      starRailAttributes: store.getters.starRailAttributes,
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      let input = value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
      if (input.length > 15) {
        return input.slice(0, 15) + '...';
      } else {
        return input;
      }
    }
  },
  computed: {

  },
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initStarRailRole();
    this.initDestinies();
    this.initLightAwls();
    this.initInRelics();
    this.initOutRelics();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initDestinies() {
      starRailDestinies().then(resp => {
        if (resp) {
          this.destinies = resp;
        }
      })
    },
    initLightAwls() {
      starRailLightAwls().then(resp => {
        if (resp) {
          this.lightAwls = resp;
        }
      })
    },
    initInRelics() {
      let params = {
        type: 2
      };
      starRailRelics(params).then(resp => {
        if (resp) {
          this.inRelics = resp;
        }
      })
    },
    initOutRelics() {
      let params = {
        type: 1
      };
      starRailRelics(params).then(resp => {
        if (resp) {
          this.outRelics = resp;
        }
      })
    },
    initStarRailRole() {
      this.loading = true;
      starRailRolePageData(this.searchStarRailRole).then(resp => {
        this.loading = false;
        if (resp) {
          this.starRailRoleData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchStarRailRole.currentPage = page;
      this.initStarRailRole();
    },
    sizeChange(size) {
      this.searchStarRailRole.size = size;
      this.initStarRailRole();
    },
    deleteStarRailRole(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]该角色, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        delStarRailRole(data.id).then(resp => {
              if (resp) {
                this.initStarRailRole();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddStarRailRoleView() {
      this.starRailRole = {
        id: null,
        name: '',
        star: null,
        attributes: '',
        destinyId: null,
        lightAwlId: null,
        relicOutId: null,
        relicOutTwoId: null,
        relicInId: null,
        remark: ''
      };
      this.dialogVisible = true;
      this.title = "添加角色";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateStarRailRoleView(data) {
      // 拷贝
      this.starRailRole = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
      this.title = "编辑角色";
      this.changeUpTwo();
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 内容赋值
        editor.txt.html("");
        editor.txt.html(this.starRailRole.remark);
      })
    },
    doStarRailRole() {
      if (this.starRailRole.id) {
        // 编辑
        this.$refs["starRailRoleForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.starRailRole.remark = editor.txt.html();
            upStarRailRole(this.starRailRole).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initStarRailRole();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["starRailRoleForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.starRailRole.remark = editor.txt.html();
            addStarRailRole(this.starRailRole).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initStarRailRole();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    closeDialog() {
      this.$refs["starRailRoleForm"].resetFields();
    },
    resetStarRailRole() {
      this.searchStarRailRole.name = "";
      this.searchStarRailRole.star = null;
      this.searchStarRailRole.attributes = '';
      this.searchStarRailRole.destinyId = null;
      this.searchStarRailRole.lightAwlId = null;
    },
    changeUpTwo() {
      if (this.starRailRole.relicOutId) {
        // 先为空 然后剔除已选择
        this.outTwoRelics = [];
        this.outRelics.forEach(item => {
          if (item.id !== this.starRailRole.relicOutId) {
            let data = {
              id: item.id,
              name: item.name
            };
            this.outTwoRelics.push(data);
          }
        });
      } else {
        this.starRailRole.relicOutTwoId = null;
        this.outTwoRelics = [];
      }
    }
  },
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
