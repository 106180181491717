<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="帕鲁编号"
            v-model="searchPalu.plid"
            clearable
            @keydown.enter.native="searchPalu.currentPage = 1;searchPalu.size = 200;initPalu()"
        ></el-input>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="帕鲁名称"
            v-model="searchPalu.name"
            clearable
            @keydown.enter.native="searchPalu.currentPage = 1;searchPalu.size = 200;initPalu()"
        ></el-input>
        <el-select
            v-model="searchPalu.attribute"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="帕鲁属性"
            clearable
            filterable
            @change="searchPalu.currentPage = 1;searchPalu.size = 200;initPalu()"
        >
          <el-option
              v-for="item in plAttribute"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchPalu.work"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="帕鲁工作"
            clearable
            filterable
            @change="searchPalu.currentPage = 1;searchPalu.size = 200;initPalu()"
        >
          <el-option
              v-for="item in plWork"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-select
            v-model="searchPalu.appetite"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="帕鲁食量"
            clearable
            filterable
            @change="searchPalu.currentPage = 1;searchPalu.size = 200;initPalu()"
        >
          <el-option
              v-for="item in plAppetite"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="帕鲁掉落"
            v-model="searchPalu.fall"
            clearable
            @keydown.enter.native="searchPalu.currentPage = 1;searchPalu.size = 10 ;initPalu()"
        ></el-input>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetPalu">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchPalu.currentPage = 1;searchPalu.size = 200;initPalu()"
        >
          搜索
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="paluData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          @sort-change="sortChange"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            prop="plid"
            align="center"
            width="120"
            label="帕鲁编号"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            align="center"
            width="120"
            label="帕鲁名称"
        >
        </el-table-column>
        <el-table-column
            prop="attribute"
            align="center"
            width="120"
            label="帕鲁属性"
        >
          <template slot-scope="scope">
            <el-tag :style="{ marginLeft: marginLeftHandler(index) }" v-for="(item, index) in scope.row.attribute"
                    type="primary">{{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="appetite"
            align="center"
            sortable
            width="120"
            label="帕鲁食量"
        >
        </el-table-column>
        <el-table-column
            prop="work"
            align="center"
            width="350"
            sortable="custom"
            label="帕鲁工作"
        >
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.work">
              <el-tag :style="{ marginLeft: marginLeftHandler(index) }"
                        v-if="searchPalu.work === item.substr(0, 2)"
                        type="danger">{{ item }}
              </el-tag>
              <el-tag :style="{ marginLeft: marginLeftHandler(index) }"
                      v-else
                      type="success">{{ item }}
              </el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column
            prop="fall"
            label="帕鲁掉落"
        >
          <template slot-scope="scope">
            <el-tag :style="{ marginLeft: marginLeftHandler(index) }" v-for="(item, index) in scope.row.fall"
                    type="danger">{{ item }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :page-sizes="[200]"
          :total="total"
          :current-page="searchPalu.currentPage"
          :page-size="searchPalu.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {paluPageData} from "@/api/pl/palu";

export default {
  name: "Palu",
  data() {
    return {
      loading: false,
      searchPalu: {
        plid: '',
        name: '',
        attribute: '',
        work: '',
        appetite: null,
        fall: '',
        sort: 0,
        currentPage: 1,
        size: 200,
      },
      paluData: [],
      total: 0,
      plAttribute: store.getters.plAttribute,
      plWork: store.getters.plWork,
      plAppetite: store.getters.plAppetite,
    }
  },
  computed: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
  },
  mounted() {
    this.initPalu();
  },
  methods: {
    initPalu() {
      this.loading = true;
      paluPageData(this.searchPalu).then(resp => {
        this.loading = false;
        if (resp) {
          this.total = resp.total;
          this.paluData = resp.data;
        }
      })
    },
    marginLeftHandler(index) {
      // 除了第一个元素 后面都 margin-left 5px
      if (index !== 0) {
        return '5px';
      }
    },
    resetPalu() {
      this.searchPalu.plid = '';
      this.searchPalu.name = '';
      this.searchPalu.attribute = '';
      this.searchPalu.work = '';
      this.searchPalu.appetite = null;
      this.searchPalu.fall = '';
      this.searchPalu.sort = 0;
    },
    currentChange(page) {
      this.searchPalu.currentPage = page;
      this.initPalu();
    },
    sizeChange(page) {
      this.searchPalu.size = page;
      this.initPalu();
    },
    sortChange(column) {
      if (column.order === "ascending") {
        // 升序
        this.searchPalu.sort = 1;
      } else if (column.order === "descending") {
        // 降序
        this.searchPalu.sort = 2;
      } else {
        // 原始顺序
        this.searchPalu.sort = 0;
      }
      this.initPalu();
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>