import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";
import { downloadRequest } from "@/utils/download";

// 查看所有账本
export function accountBooks() {
  return getRequest("/person/account-record/getAccountBookList");
}

// 支出图表统计数据
export function accountExpenditureCount(params = {}) {
  return postRequest("/person/account-record/expenditureCount", params);
}

// 收入图表统计数据
export function accountIncomeCount(params = {}) {
  return postRequest("/person/account-record/incomeCount", params);
}

// 账单记录列表
export function accountPageData(params = {}) {
  return postRequest("/person/account-record/getPageData", params);
}

// 账单金额汇总
export function accountAmtSummary(params = {}) {
  return postRequest("/person/account-record/getAmtSummary", params);
}

// 支出金额数据
export function getExpenditureAmtSummary(params = {}) {
  return postRequest("/person/account-record/getExpenditureAmtSummary", params);
}

// 删除账单
export function delAccountRecord(id) {
  return deleteRequest("/person/account-record/" + id);
}

// 批量删除账单
export function delAccountRecords(params = {}) {
  return deleteRequest("/person/account-record", params);
}

// 添加账单
export function addAccountRecord(params = {}) {
  return postRequest("/person/account-record", params);
}

// 更新账单
export function upAccountRecord(params = {}) {
  return putRequest("/person/account-record", params);
}

// 删除账本
export function delAccountBook(id) {
  return deleteRequest("/person/account-record/deleteAccountBook/" + id);
}

// 更新账本
export function upAccountBook(params = {}) {
  return putRequest("/person/account-record/updateAccountBook", params);
}

// 新增账本
export function addAccountBook(params = {}) {
  return postRequest("/person/account-record/addAccountBook", params);
}

// 根据类别获取对应的账单类型
export function accountTypesByCategory(id) {
  return getRequest("/person/account-record/getTypesByCategory/" + id);
}

// 删除账单类型
export function delAccountCategory(id) {
  return deleteRequest("/person/account-record/deleteAccountCategory/" + id);
}

// 更新账单类型
export function upAccountCategory(params = {}) {
  return putRequest("/person/account-record/updateAccountCategory", params);
}

// 新增账单类型
export function addAccountCategory(params = {}) {
  return postRequest("/person/account-record/addAccountCategory", params);
}

// 账单记录导出
export function accountExport(params = {}) {
  return downloadRequest("/person/account-record/export", params);
}
