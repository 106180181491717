import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "@/utils/api.js";

// 日记列表
export function dayRecordPageData(params = {}) {
  return postRequest("/work/day-record/getPageData", params);
}

// 删除日记
export function delDayRecord(id) {
  return deleteRequest("/work/day-record/" + id);
}

// 添加日记
export function addDayRecord(params = {}) {
  return postRequest("/work/day-record", params);
}

// 更新日记
export function upDayRecord(params = {}) {
  return putRequest("/work/day-record", params);
}
